@media screen and (max-width: 460px) {
  .FaQ-container {

    width: 100%;
    padding: 5%;
    margin-top: 5%;
    .faq-freqq{
      padding: 3% 4%;
      border-radius: 12px;
      .fre{
        margin-left: 0;
      }
    }
    .accordinHeader {
      border-left: 4px solid #242B52 !important;
    }
  }
}

@media screen and (min-width: 760px) {
  .FaQ-container {

    width: 1200px;
    margin: auto;
    margin-top: 30px;
    padding: 40px;
    .faq-freqq{
      padding: 22px 40px;
      border-radius: 12px;
      white-space: nowrap;
      width: max-content;
      
      .fre{
        margin-left: 0;
        line-height: 0;
      }
    }
    .accordinHeader {
      border-left: 4px solid #242B52 !important;
    }
  }
}