@media screen and (max-width: 460px) {
    .button-container {
        border-radius: 9.97px;
        position: sticky;
        top:44%;
        height: 51.842px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 220220;
        text-align: center;
        font-family: Manrope;
        font-size: 15.951px;
        font-style: normal;
        font-weight: 700;
        line-height: 19.939px;
        cursor: pointer;
        /* 125% */
    }
    
    .button-container:hover{
        background-color: #2552b2bf;
    }
    @supports (-webkit-touch-callout: none) {
        .button-container{
            font-weight: 600;
        }
    }
}
@media screen and (min-width: 760px){
    .button-container {
        border-radius: 9.97px;
        position: sticky;
        height: 74px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 220220;
        text-align: center;
        font-family: Manrope;
        font-size: 19px;
        font-style: normal;
        font-weight: 700;
        line-height: 29.5px;
        cursor: pointer;
        /* 125% */
    }
    
    .button-container:hover{
        background-color: #2552b2bf;
    }
    @supports (-webkit-touch-callout: none) {
        .button-container{
            font-weight: 600;
        }
    }
}