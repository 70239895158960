@media screen and (max-width: 460px) {
    .imgBtn {
        width: 43vw;
        position: relative;
        border-radius: 23px;
        height: 68px;
        margin-bottom: 7%;

        .imgg {
            display: flex;
            justify-content: center;
            width: 100%;
            height: 72.298px;
            position: absolute;
            top: -46px;
            left: 0;

            img {
                max-height: 100%;
                max-width: 100%;
            }
        }

        .textImg {
            display: flex;
            justify-content: center;
            margin-top: 32px;

            .texttext {
                color: #000;
                font-family: Manrope;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                letter-spacing: 0.018px;
            }
        }
    }

    .active {
        border: 2px solid rgba(32, 80, 255, 0.80);
        background: rgba(32, 80, 255, 0.18);
    }
}

@media screen and (min-width: 760px) {
    .imgBtn {
        width: 30%;
        position: relative;
        border-radius: 23px;
        height: 68px;
        margin-top: 14%;
        cursor: pointer;

        .imgg {
            display: flex;
            justify-content: center;
            width: 95px;
            height: auto;
            position: absolute;
            top: -75px;
            left: 22%;

            img {
                max-height: 100%;
                max-width: 100%;
            }
        }

        .textImg {
            display: flex;
            justify-content: center;
            margin-top: 32px;

            .texttext {
                color: #000;
                font-family: Manrope;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                letter-spacing: 0.018px;
            }
        }
    }
    .imgBtn:last-child{
        margin-bottom: 40px;
    }
    .imgBtn:last-child:first-child{
        margin-top: 40px !important;
    }
    .imgBtn:nth-child(4){
        margin-top: 40px;
    }
    .imgBtn:nth-child(5){
        margin-top: 40px;
    }
    .imgBtn:nth-child(6){
        margin-top: 40px;
    }
    .imgBtn:nth-child(1){
        margin-top: 60px;
    }
    .imgBtn:nth-child(2){
        margin-top: 60px;
    }
    .imgBtn:nth-child(3){
        margin-top: 60px;
    }
    .active {
        border: 2px solid rgba(32, 80, 255, 0.80);
        background: rgba(32, 80, 255, 0.18);
    }
}