@media screen and (max-width: 460px) {
    .HeaderContainer_v4 {
        width: 100%;
        padding: 0% 5%;
        position: sticky;
        top: 0;
        left: 0;
        height: 53px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #000;
        font-family: Manrope;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px;
        background: rgb(243, 245, 250);
        z-index: 400000;
        /* 91.667% */
        letter-spacing: 0.24px;
        border-bottom: 0.5px solid #B8B8B8;

        .logo_Contaniner_v4 {
            height: 34px;
            width: 34px;
        }

        img {
            height: 34px;
            width: 34px;
        }

        .whatsapp_v4 {
            width: 43.194px;
            height: 43.417px;
        }
    }

    @supports (-webkit-touch-callout: none) {
        .HeaderContainer_v4 {
            font-weight: 600;
        }
    }

}

@media screen and (min-width: 760px) {
    .HeaderContainer_v4 {
        width: 1200px;
        padding: 2% 0%;
        // position: sticky;
        margin: auto;
        top: 0;
        left: 0;
        height: 53px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #000;
        font-family: Manrope;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px;
        background: rgb(243, 245, 250);
        z-index: 400;
        /* 91.667% */
        letter-spacing: 0.24px;


        .logo_Contaniner_v4 {
            height: 56px;
            // width: 64px;
        }

        img {
            height: 100%;
            // width: 64px;
        }

        .whatsapp_v4 {
            width: 68.194px;
            height: 68.417px;
        }
    }

    @supports (-webkit-touch-callout: none) {
        .HeaderContainer_v4 {
            font-weight: 600;
        }
    }
}