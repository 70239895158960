@media screen and (max-width: 460px) {
    .middleBannerContainer{
        background-color: #FFF;
        width: 100%;
        margin-top: 5%;
        .innerMIddleContainer{
           width: 90%;
           padding: 20px 0;
           margin: auto;
           .innerinnerMiddleContainer{
             width: 100%;
             display: flex;
             margin: auto;
             justify-content: space-around;
             align-items: center;
             .hesss{
                 border: 0.5px solid black;
                 height: 100px;
             }
             .hesss:last-child{
                 height: 0;
             }
             .IconTextContainer{
                 display: flex;
                 flex-direction: column;
                 justify-content: center;
                 align-items: center;
                 img{
                    width: 60px;
                    margin-bottom: 11px;
                 }
             
                 .middleBannerText{
                     width: 80%;
                     text-align: center;
                     font-size: 11px;
                     font-weight: 600;
                     font-family: Manrope;
                 }
                 @supports (-webkit-touch-callout: none) {
                    .middleBannerText {
                        font-weight: 550;
                    }
                }
             }
           }
        }
    }
}
@media screen and (min-width: 760px) {
   .middleBannerContainer{
       background-color: #FFF;
       width: 100%;
       margin-top: 30px;
       .innerMIddleContainer{
          width: 1200px;
          padding: 20px 0;
          margin: auto;
          .innerinnerMiddleContainer{
            width: 1000px;
            display: flex;
            margin: auto;
            justify-content: space-around;
            align-items: center;
            .hesss{
                border: 0.5px solid black;
                height: 100px;
            }
            .hesss:last-child{
                height: 0;
            }
            .IconTextContainer{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                img{
                    margin-bottom: 18px;
                    width: 80px;
                    height: auto;
                }
                .middleBannerText{
                    width: 80%;
                    text-align: center;
                    font-weight: 600;
                    font-family: Manrope;
                }
            }
          }
       }
   }
}