@media screen and (max-width: 460px) {
    .starReview{
        width:12px; 
        height:11px;
    }
}
@media screen and (min-width: 760px) {
    .starReview{
        width:22px; 
        height:21px;
    }
}