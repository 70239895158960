@media screen and (max-width: 460px) {
    .formModelContainer {
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        min-height: 100svh;
        background-color: rgba(8, 8, 8, 0.4);
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(5px);

        .innerFormContainer {
            position: absolute;
            bottom: 0;
            background-color: #FFF;
            width: 100%;
            border-top-left-radius: 22px;
            border-top-right-radius: 22px;

            .modelForm {
                display: flex;
                flex-direction: column;
                gap: 10px;
                img{
                    width: 35px ;
                }
                .cityName {
                    font-size: 20px;
                }
            }

            .input-container-img-inputBox {
                height: 62px;

                .img-container {
                    // img {
                    //     width: 58px;
                    // }
                }

                input {
                    font-size: 20px;
                }

            }

            .form-button {
                width: 90vw;
                margin: auto;
                height: 52px;
                border-radius: 12px;
                color: #FFF;
                margin-top: 4vw;
                text-align: center;
                font-weight: Manrope;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 20px;
                /* 125% */
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 5vw;

            }
        }
    }

}

@media screen and (min-width: 760px) {
    .formModelContainer {
        width: 100%;

        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(8, 8, 8, 0.4);
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(5px);
        height: 100svh;

        .innerFormContainer {
            width: 600px;
            padding: 40px;
            background-color: #FFF;
            border-radius: 22px;


            .modelForm {
                display: flex;
                flex-direction: column;
                gap: 30px;

                .cityName {
                    font-size: 20px;
                }
            }

            .popBox {
                width: 100%;
                margin: 0;
                margin-top: 0px;
                position: relative;
                top: -30px;
            }

            .field-error {
                margin-top: -30px;
                margin-left: 0;
            }

            .input-container-img-inputBox {
                height: 62px;
                margin: 0;
                width: 100%;
                border-radius: 12px;
                position: relative;

                .img-container {
                    img {
                        width: 58px;
                    }
                }

                input {
                    font-size: 20px;
                }

            }

            .form-button {
                width: 100%;
                margin: auto;
                height: 52px;
                border-radius: 12px;
                color: #FFF;
                margin-top: 10px;
                text-align: center;
                font-weight: Manrope;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 20px;
                cursor: pointer;
                /* 125% */
                display: flex;
                align-items: center;
                justify-content: center;

            }
        }
    }

}