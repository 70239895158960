@media screen and (max-width: 460px) {
    .mainContainer{
        background: #F3F5FA; 
        height: 100svh; 
        width: 100vw;
        overflow:scroll;
        position:relative;
    }
    .AsSeen-Container {
        background-color: #FFF;
        margin-top: 5%;
        position: relative;
        z-index: 0;
        padding-top: 20px;
    }
    .ProductHeighLight{
        background-color: #FFF;
        width: 100%;
        padding: 5% 0;
        margin-top: 5%;
        .innerProductHeighLight{
           margin:0 5%;
           width: 90%;
        }
    }
    .writereviewContainer{
        width: 90%;
        margin: 5%;
    }
    .OurClinets{
        background-color: #FFF;
        margin-top: 5%;
        margin-bottom: 5%;
        padding-bottom: 4%;
    }
    @supports (-webkit-touch-callout: none) {
        .fontWIos{
        font-weight: 600 !important;
        }
    }
}

@media screen and (min-width: 760px){
    // #root{
    //   background-color:#F3F5FA !important;
    //   width: 100% !important;
    // }
    .webContainer {
        max-width: 1200px;
        padding: 0 10px;
    }
    .mainContainer{
        background: #F3F5FA; 
        width: 100%;
        margin: auto;
        overflow:scroll;
        position:relative;
        .web-selectedPlan-PlanDetail-Container{
            display: flex;
            width: 1110px;
            gap: 20px;
            margin: auto;
        }
        .web-subHeader{
            width:1099px;
            margin: auto;
            margin-top: 56px;
            display: flex;
            gap: 20px;
            flex-direction: column;
            margin-bottom: 40px;
        }
    }
    .AsSeen-Container {
        background-color: #FFF;
        margin-top: 20px;
        padding-top: 20px;
        .innerASeen{
            width: 1200px;
            margin: auto;
        }
    }
    .ProductHeighLight{
        background-color: #FFF;
        width: 100%;
        padding: 40px 0;
        margin: auto;
        margin-top: 30px;
        .innerProductHeighLight{
           margin:0 5%;
           margin: auto;
           display: flex;
           gap: 40px;
           flex-direction: column;
           width: 1200px;
        }
    }
    .OurClinets{
        background-color: #FFF;
        margin-top:30px;
        margin-bottom: 30px;
        
    }
    .webCustomerReview{
        width: 100%;
        background-color: #FFF;
    }
}