@media screen and (max-width: 460px) {

    .customerReview-Container {
        background-color: #FFF;
        margin-top: 5%;
        padding: 5%;

        .rating-contaoner {
            border-radius: 11.919px;
            border: 0.5px solid #DDD;
            background: #FFF;
            box-shadow: 0px 0.993px 17.879px 0px rgba(0, 0, 0, 0.08);
            
            margin-top: 20px;
            padding: 5%;
            margin-bottom: 5%;

            .overallH {
                color: #A8A8A8;
                font-family: Manrope;
                font-size: 12.458px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
            .overallR {
                color: #A8A8A8;
                font-family: Manrope;
                font-size: 12.458px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
            @supports (-webkit-touch-callout: none) {
                .overallH{
                    font-weight: 500;
                }
                .overallR{
                    font-weight: 500;
                }
            }

            .star-rating-container {
                display: flex;
                gap: 19px;
                align-items: center;
                margin: 2% 0;
            }

            .rating-text {
                color: #000;
                font-family: Manrope;
                font-size: 46.812px;
              
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
            @supports (-webkit-touch-callout: none) {
                .rating-text{
                    font-weight: 600;
                }
            }
            .star-container {
                display: flex;
            }

            .ratingNumber {
                color: #2557D9;
                font-family: Manrope;
                font-size: 13.051px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
            @supports (-webkit-touch-callout: none) {
                .ratingNumber{
                    font-weight: 600;
                }
            }
            .starPercentage {
                color: #1F2047;
                font-family: Manrope;
                font-size: 13.051px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
            @supports (-webkit-touch-callout: none) {
                .starPercentage{
                    font-weight: 600;
                }
            }
            .singleRowRating {
                width: 100%;
                display: flex;
                gap: 2%;
                align-items: center;
                justify-content: space-evenly
            }

            .progreesOuter {
                width: 80%;
                background-color: #F5F8FF;
                border-radius: 12px;
                height: 10.3759px;
                position: relative;
            }
            .goldBar{
                
                position: absolute;
                height: 10.3759px;
                border-radius: 12px;
                background-color: #FFCC48;
                z-index: 3;
            }
            .innerBar{
                width: 100%;
                height: 10.3759px;
                border-radius: 12px;
                position: absolute;
                z-index: 2;
                background-color: #F5F8FF;
            }
        }
    }
    
}

@media screen and (min-width: 760px) {

    .customerReview-Container {
        background-color: #FFF;
        margin: auto;
        margin-top: 30px;
        padding: 40px;
        width: 1200px;

        .reviewBothContainer{
            display: flex;
            gap: 20px;
            width: 100%;
            margin-top: 40px;
            .reviewRightContainer{
                width: 60%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 325px;
                gap: 20px;

            }
        }
        .rating-contaoner {
            border-radius: 11.919px;
            min-width: 40%;
            height: min-content;
            border: 0.5px solid #DDD;
            background: #FFF;
            box-shadow: 0px 0.993px 17.879px 0px rgba(0, 0, 0, 0.08);
            
            // margin-top: 20px;
            padding: 29px 32px;
            // margin-bottom: 5%;

            .overallH {
                color: #A8A8A8;
                font-family: Manrope;
                font-size: 18.458px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
            .overallR {
                color: #A8A8A8;
                font-family: Manrope;
                font-size: 15px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }

            @supports (-webkit-touch-callout: none) {
                .overallH{
                    font-weight: 500;
                }
                .overallR{
                    font-weight: 500;
                }
            }

            .star-rating-container {
                display: flex;
                gap: 19px;
                align-items: center;
                margin: 14.5px 0;
                margin-bottom: 15.5px;
            }

            .rating-text {
                color: #000;
                font-family: Manrope;
                font-size: 71.812px;
              
                font-style: normal;
                font-weight: 700;
                line-height: 0;
            }
            @supports (-webkit-touch-callout: none) {
                .rating-text{
                    font-weight: 600;
                }
            }
            .star-container {
                display: flex;
            }

            .ratingNumber {
                color: #2557D9;
                font-family: Manrope;
                font-size: 16.051px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
            @supports (-webkit-touch-callout: none) {
                .ratingNumber{
                    font-weight: 600;
                }
            }
            .starPercentage {
                color: #1F2047;
                font-family: Manrope;
                font-size: 16.051px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
            @supports (-webkit-touch-callout: none) {
                .starPercentage{
                    font-weight: 600;
                }
            }
            .singleRowRating {
                width: 100%;
                display: flex;
                gap: 2%;
                align-items: center;
                justify-content: space-evenly
            }

            .progreesOuter {
                width: 80%;
                background-color: #F5F8FF;
                border-radius: 12px;
                height: 14px;
                position: relative;
            }
            .goldBar{
                
                position: absolute;
                height: 14px;
                border-radius: 12px;
                background-color: #FFCC48;
                z-index: 3;
            }
            .innerBar{
                width: 100%;
                height: 14px;
                border-radius: 12px;
                position: absolute;
                z-index: 2;
                background-color: #F5F8FF;
            }
        }
    }
    
}