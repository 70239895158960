@media screen and (max-width: 460px) {
    .subHeader {
        color: rgba(14, 17, 42, 0.70);
        font-family: Manrope;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 17.879px;
        width: 100%;
        margin-top: 8px;
    }
}
@media screen and (min-width: 760px){
    .subHeader {
        color: rgba(14, 17, 42, 0.70);
        font-family: Manrope;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: 17.879px;
        width: 100%;
        margin-top: 8px;
    }
}