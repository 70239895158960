
  @font-face {
    font-family: "Manrope";
    src: local("Manrope"),url(../public/Manrope-VariableFont_wght.ttf) format("truetype");
  }
  @font-face {
    font-family: "Lato";
    src: local("Lato"),url(../public/Lato-Regular.ttf) format("truetype");
  }
  @font-face {
    font-family: "LatoBold";
    src: local("LatoBold"),url(../public/Lato-Bold.ttf) format("truetype");
  }
  .font-face-gm{
  
    font-family: Manrope;
    /* font-family: 'Manrope'; */
  }