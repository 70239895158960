@media screen and (max-width: 460px) {
  .slider-container {
    width: 90vw;
    margin: auto;
  }

  .slider-img {
    height: 90vw;
    border-radius: 8px;
    overflow: hidden;
    margin-top: 19.50px;
 
    margin-left: auto;
    margin-right: auto;
  }

  .control-arrow {
    display: none;
  }

  .carousel-status {
    display: none;
  }

  .thumbs-wrapper {
    margin-top: 0px !important;
    margin-left: 0% !important;
    margin-right: 0% !important;
    margin-bottom: 0px !important;
    overflow: scroll;
  }

  .thumbs {
    padding-right: 150px;
    /* margin-right: 150px; */
    padding-left: 0;
    margin-bottom: 7px;
    
  }
  .slider-img img{
       max-width: 100%;
       height: auto;
  }
  .slider-img video{
    height: auto;
    width: 100%;
}
  .carousel .thumb img {
    /* border-radius: 8px; */
    width: 100%;
    height: 100%;
  }

  .carousel .thumb {
    padding: 0;
    border: none;
    width: 72px !important;
    height: 72px !important;
    margin-top: 5%;
    margin-right: 5%;
    border-radius: 8px;
    transition: none !important;
  
  }
  .carousel img{
    pointer-events: visible !important;
  }
  .carousel .thumb.selected,
  .carousel .thumb:hover {
    border: 1.5px solid blue;
    border-radius: 9px;

  }
  .control-dots{
    display: none;
  }
  /* .video{
    border-radius: 23px;
    width: 100%;
    height: 355px;
  } */

  
.tumb-container{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    overflow: scroll;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
}
.tumb-container::-webkit-scrollbar{
   display: none;
}
.single-tumb-container{
  height: 72px;
  width: 72px;
  border-radius: 8px;
  margin-top: 10px;
  margin-right: 10px;
  
}
.single-tumb-container:last-child{
  height: 72px;
  width: 72px;
  border-radius: 8px;
  margin-top: 10px;
  margin-right: 0px;
  
}
.single-tumb-container:hover{
  height: 72px;
  min-width: 72px;
  border: 1px solid blue;
}
.single-tumb-container img{
  height: 70px;
  min-width: 70px;
  border-radius: 8px;
}
@supports (-webkit-touch-callout: none) {
  .slider-img {
    height: 350px;
    border-radius: 8px;
    overflow: hidden;
    margin-top: 19.50px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  .slider-img video{
    height: auto;
    width: 100%;
    border-radius: 8px;
}
 }

}

@media screen and (min-width: 760px) {
  .slider-container {
    width: 510px;
    
    
  }

  .slider-img {
    width: 510px;
    height: 430px;
    border-radius: 8px;
    overflow: hidden;
    margin-top: 19.50px;
  }
 
  .slider-img img{
    height: 100%;
  }

  .control-arrow {
    display: none;
  }

  .carousel-status {
    display: none;
  }

  .thumbs-wrapper {
    margin-top: 0px !important;
  }

  .thumbs {
    padding: 0;
    overflow-x: scroll;
    
    width: 100% !important;
  }
 .thumbs::-webkit-scrollbar{
    display: none;
  }
  .carousel .thumb img {
    border-radius: 8px;
    width: 100%;
    height: 100%;
  }

  .carousel .thumb {
    padding: 0;
    border: none;
    width: 104.21px !important;
    height: 103.47px;
  }

  .carousel .thumb.selected,
  .carousel .thumb:hover {
    border: 1.5px solid blue;
    border-radius: 9px;

  }
  .carousel .thumb{
    margin-right: 14.55px;
    margin-top: 14.55px;
    
  }
  .carousel .thumb:last-child{
    margin-right:0px;
    margin-top: 14.55px;
    
  }
  .thumbs {
    transform: translate3d(0px,0px,0px) !important;
  }
  .carousel .thumbs-wrapper{
    margin: 0px;
  }
  /* .animated{
    transform: translate3d(0px,0px,0px) !important;
  } */
  .control-dots{
    display: none;
  }
  .carousel{
    width: 100% !important;
    height: 430px;
    border-radius: 8px;
  }
  .video{
    border-radius: 8px;
    height:430px;
  }
  .tumb-container{
    width: 510px;
    margin-left: 0;
    
    overflow: scroll;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
}
.tumb-container::-webkit-scrollbar{
   display: none;
}
.single-tumb-container{
  height: 100px;
  width: 100px;
  border-radius: 8px;
  margin-top: 10px;
  margin-right: 10px;
  
}
.single-tumb-container:last-child{
  height: 100px;
  width: 100px;
  border-radius: 8px;
  margin-top: 10px;
  margin-right: 0px;
  
}
.single-tumb-container:hover{
  height: 100px;
  min-width: 100px;
  border: 1px solid blue;
}
.single-tumb-container img{
  height: 98px;
  min-width: 98px;
  border-radius: 8px;
}

}