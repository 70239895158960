@media screen and (max-width: 460px) {
    .popContainer {
        height: 100svh;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        display: flex;
        align-items: flex-end;
        z-index: 2000000;
       
        // backdrop-filter:blur(2px) '
        .arrow{
            position: absolute; 
            left:5%;
            top:38%;
            height: 15px;
            width: 12px;
        }
    }

    .innerContainer {
        margin-top: 10%;
        background-color: rgba(8, 8, 8, 0.4);
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(5px);
        width: 100vw;
        border-top-left-radius: 22px;
        border-top-right-radius: 22px;
        background-color: #FFF;
        z-index: 100000;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    }

    .numberContainer {
        // width: svw;
        display: flex;
        justify-content: center;
        margin-top: 27px;
    }

    .boldNumber {
        color: #18181C;
        font-family: Manrope;
        font-size: 25.217px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.025px;
    }

    .smallNumber {
        color: #C9CBD1;
        font-family: Manrope;
        font-size: 15.13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.015px;
    }

    .lineProgressBar {
        position: relative;
        height: 3px;
        width: 80svw;
        margin: auto;
        display: flex;
        margin-top: 19px;

        .lineProgrssfull {
            height: 1px;
            width: 80vw;
            margin: auto;
            background: #D0D8EF;
        }

        .lineNotFull {
            // width: width;
            margin: auto;
            background: #1D48D3;
            position: absolute;
            left: 0;
            top: 0px;
            height: 3px;
            border-radius: 100svw;
        }
    }

    .qustionTextContainer {
        width: 90svw;
        margin: auto;
        color: #000;
        font-family: Manrope;
        font-size: 28px;
        margin-top: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.037px;
        margin-bottom: 22px;

    }

    .btn1{
        width: 90vw;
        margin: auto;
        height: 52px;
        border-radius: 12px;
        color: #FFF;
        margin-top: 4vw;
        text-align: center;
        font-weight: Manrope;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        /* 125% */
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 5vw;

    }
    .imgButtonContainer {
        width: 90vw; 
        margin: auto; 
        display: flex; 
        flex-wrap: wrap;
        gap: 4vw
    }

    @supports (-webkit-touch-callout: none) {
        .popContainer {

            .qustionTextContainer {
                font-weight: 600;
            }
        }
    }
}

@media screen and (min-width: 760px) {
    .popContainer {
        height: 100svh;
        width: 100vw;
        position: fixed;
        top: 0;
        left: 0;
        background-color: rgba(8, 8, 8, 0.6);
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(5px);
       

        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2000000;
        .arrow{
            position: absolute; 
            left:0%;
            top:0%;
            height: 70px;
            width: 13px;
        }
    }

    .innerContainer {
        padding: 22px;
        width: 747px;
        padding: 40px;
        
        border-radius: 22px;
        background-color: #FFF;
        z-index: 100000;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    }

    .numberContainer {
        // width: svw;
        display: flex;
        justify-content: center;
        // margin-top: 27px;
    }

    .boldNumber {
        color: #18181C;
        font-family: Manrope;
        font-size: 25.22px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.025px;
    }

    .smallNumber {
        color: #C9CBD1;
        font-family: Manrope;
        font-size: 15.13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.015px;
    }

    .lineProgressBar {
        position: relative;
        height: 3px;
        width: 40%;
        margin: auto;
        display: flex;
        margin-top: 30px;

        .lineProgrssfull {
            height: 1px;
            width: 100%;
            margin: auto;
            background: #D0D8EF;
        }

        .lineNotFull {
            // width: width;
            margin: auto;
            background: #1D48D3;
            position: absolute;
            left: 0;
            top: 0px;
            height: 3px;
            border-radius: 100svw;
        }
    }

    .qustionTextContainer {
        width: 100%;
        text-align: center;
        margin: auto;
        color: #000;
        font-family: Manrope;
        font-size: 30px;
        margin-top: 30px;
    
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.037px;

    }

    .btn1 {
        width: 349px;
        margin: auto;
        height: 52px;
        border-radius: 12px;
        color: #FFF;
        margin-top: 0;
        text-align: center;
        font-weight: Manrope;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .imgButtonContainer {
        width: 100%; 
        margin: auto; 
        display: flex; 
        flex-wrap: wrap;
        gap: 39px
    }

    @supports (-webkit-touch-callout: none) {
        .popContainer {

            .qustionTextContainer {
                font-weight: 600;
            }
        }
    }
}