@media screen and (max-width: 460px) {
    .goldStar{
      width: 29px;
        height: 35px;
    
    }
}
@media screen and (min-width: 760px) {
    .goldStar{
        width: 44px;
        height: 45px;
    }
}