@media screen and (max-width: 460px) {
    .main-header-navbar {
        display: none;
    }

    .main-background {
        background-color: black;
        height: 100%;
        padding-bottom: 20px;
    }

    .bottom-wab-main {
        
    }

    .main-rupess-header {
        height: 80px;
        left: 34px;
        top: 41px;

        font-family: 'Manrope';
        font-style: normal;
        font-weight: 700;
        font-size: 28px;
        font-family: 'Manrope';
        font-style: normal;
        line-height: 40px;
        /* or 143% */


        color: #FFFFFF;
    }

    .main-day {
        height: 80px;
        left: 34px;
        top: 41px;

        font-family: 'Manrope';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 40px;

        /* or 200% */


        color: #FFFFFF;
    }

    .main-topHeader {
        width: 100vw;
        height: 259px;
        background: rgba(32, 80, 255, 0.5);
        border-radius: 0px 0px 10px 10px;
    }

    .main-topHeader-inner {
        padding-top: 41px;
        display: flex;
        width: 100%;
    }

    .main-header-text {
        width: 50%;
        padding-left: 34px;
    }

    .mobikwik-container {
        height: 72px;
        background: #121212;
        box-shadow: 0px 1px 18px rgba(0, 0, 0, 0.08);
        border-radius: 12px;
        width: 90%;
        margin-left: 5%;
        margin-right: 5%;
        margin-top: 24px;
        display: flex;
        /* flex-direction: column; */
        /* justify-content: center; */
        align-items: center;

    }

    .mobikwik-img-container img {
        margin-left: 20px;
        width: 47px;
        height: 39px;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        margin-right: 15px;
    }

    .mobikwik-text-container .header {
        height: 22px;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 800;
        font-size: 18px;
        line-height: 22px;
        color: #FFFFFF;
    }

    .mobikwik-text-container .subHead {
        height: 18px;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 18px;
        color: #B8B8B8;
        opacity: 0.8;
    }

    .main-header-mainText {
        height: 80px;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 400;
        font-size: 28px;
        line-height: 40px;
        color: #FFFFFF;
    }

    .main-header-subText {
        height: 60px;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: rgba(184, 184, 184, 0.7);
    }

    .main-header-img-container {
        width: 50%;
    }

    .main-header-img {
        height: 186px;
        width: 100%;

    }

    .main-2nd-container {
        width: 100%;
        position: relative;
        height: 134px;
    }

    .main-description-container {
        /* height: 179px; */
        position: absolute;
        top: -45px;
        width: 90%;
        margin-left: 5%;
        margin-right: 5%;

        background: #121212;
        box-shadow: 0px 1px 18px rgba(0, 0, 0, 0.08);
        border-radius: 12px;
    }

    .main-img-text-container {
        display: flex;
        align-items: center;
        padding-left: 20px;
        padding-top: 19px;
    }

    .main-img-logo img {
        width: 38px;
        height: 38px;
    }

    .main-img-Cname {
        height: 22px;
        margin-left: 9px;
        top: 247px;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0.01em;
        color: #FFFFFF;
    }

    .main-description-heading {
        /* height: 36px; */
        left: 37px;
        top: 292px;
        margin-left: 20px;
        margin-right: 15px;
        margin-top: 15px;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 18px;
        border-bottom: 0.2px solid #B8B8B8;
        padding-bottom: 16px;
        color: #B8B8B8;
        opacity: 0.8;
    }

    .main-description-subHeading {
        /* height: 18px; */
        margin-left: 20px;
        margin-right: 15px;
        margin-top: 18px;
        margin-bottom: 18px;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 18px;
        color: #B8B8B8;
        opacity: 0.8;
    }

    .main-selectPlan-text-m {
        height: 38px;
        margin-top: 12px;
        margin-left: 12%;
        margin-bottom: 12px;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 800;
        font-size: 14px;
        line-height: 40px;
        color: #FFFFFF;
    }

    .main-selectPlan-text-d {
        display: none;
    }



    .main-img-logo {
        width: 38px;
        height: 38px;
    }

    .main-selectPlan-text-m span {
        height: 38px;

        font-family: 'Manrope';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 40px;
        color: #FFFFFF;
    }

    .main-radio-with-description {
        margin-left: 5%;
        width: 90%;

        left: 17px;
        top: 457px;
        background-color: #121212;
        /* background: #B8B8B8; */
        box-shadow: 0px 1px 18px rgba(0, 0, 0, 0.08);
        border-radius: 12px;
        margin-bottom: 20px;
    }

    .main-list-container {
        margin-left: 20px;
        margin-right: 20px;
        padding-top: 13px;
        border-top: 0.2px solid #B8B8B8;
        height: 110px;
    }

    .main-list {
        display: flex;
    }

    .main-list img {
        width: 14px;
        height: 9px;
        margin-right: 11px;
    }

    .main-list-text {
        margin-bottom: 5px;
        height: 36px;
        margin-right: 10px;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        color: #B8B8B8;
    }

    .main-mobile-Summary {
        display: none;
    }

    .main-payButton-container {
        position: fixed;
        width: 100%;
        height: 92px;
        display: flex;
        justify-content: space-between;
        left: 0px;
        bottom: 0px;
        background: #121212;
        border-radius: 12px;
        box-shadow: 0px -4px 18px rgba(0, 0, 0, 0.12);
        z-index: 4;
    }

    .main-payButton-value-container {
        height: 46px;
        display: flex;
        margin-left: 16px;
        margin-top: 20px;
        flex-direction: column;
        justify-content: space-between;
    }

    .main-payButton-rate {
        height: 24px;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 800;
        font-size: 18px;
        line-height: 24px;
        color: #FFFFFF;
    }

    .main-payButton-security {
        height: 18px;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
        color: rgba(184, 184, 184, 0.7);
        opacity: 0.8;
    }

    .main-payButton-btn-container {
        width: 151px;
        height: 52px;
        margin-top: 20px;
        margin-right: 15px;
        background: #2050FF;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .main-payButton-btn-text {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        color: #FFFFFF;
    }

    .main-faq-heading-container {
        width: 228px;
        height: 38px;
        margin-top: 12px;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 40px;
        margin-left: 5%;
        color: #FFFFFF;
    }

    .main-faq-video {
        margin-left: 5%;
        margin-right: 5%;
        margin-top: 18px;
        width: 90%;
        background: #121212;
        box-shadow: 0px 1px 18px rgba(0, 0, 0, 0.08);
        border-radius: 12px;
        display: flex;

        padding: 8px 0px;
        padding-top: 10px;
    }

    .main-faq-img {
        width: 44%;
        align-items: center;
        justify-content: center;
        display: flex;
    }

    .main-faq-img img {
        width: 128px;
        height: 130px;
    }

    .main-faq-rightSide {
        width: 52%;
        display: flex;
        flex-direction: column;
    }

    .main-faq-rightSide .head {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 800;
        font-size: 15px;
        line-height: 22px;
        color: #FFFFFF;
        margin-bottom: 4px;
    }

    .main-faq-rightSide .subHead {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #B8B8B8;
        opacity: 0.8;
    }

    .main-review-video {
        margin-left: 5%;
        margin-right: 5%;
        margin-top: 18px;
        width: 90%;
        background: #121212;
        box-shadow: 0px 1px 18px rgba(0, 0, 0, 0.08);
        border-radius: 12px;
        display: flex;
        padding: 8px 0px;
        padding-top: 10px;
    }

    .main-review-heading-container {
        width: 228px;
        height: 38px;
        margin-top: 30px;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 40px;
        /* or 286% */

        margin-left: 5%;
        color: #FFFFFF;
    }

    .main-review-rightSide {
        width: 64%;
        display: flex;
        margin-left: 15px;
        flex-direction: column;
        justify-content: center;
    }

    .main-review-img {
        width: 35%;
        align-items: center;
        justify-content: center;
        display: flex;
    }

    .main-review-rightSide .head {
        height: 22px;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 800;
        font-size: 15px;
        line-height: 22px;
        /* or 138% */


        color: #FFFFFF;
    }

    .main-review-rightSide .head img {
        width: 11px;
        height: 11px;
        margin-left: 6px;
    }

    .main-review-rightSide .date {
        height: 19px;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 500;
        font-size: 11px;
        line-height: 18px;
        color: #B8B8B8;
        opacity: 0.8;
    }

    .main-review-rightSide .subHead {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 19px;
        color: #B8B8B8;
        opacity: 0.8;
    }

    .main-review-img img {
        width: 91px;
        height: 156px;
    }

    .main-review-star img {
        width: 13.81px;
        height: 12.99px;
    }
    @supports (-webkit-touch-callout: none) {
        .footer-text-link{
            font-weight: 500 !important;
        }
        .footer-title-light-copy{
            font-weight: 500 !important;
            text-align: center;
        }
    }

    .main-write-review {
        margin-left: 5%;
        margin-right: 5%;
        margin-top: 18px;
        width: 90%;
        height: 52px;
        background: #121212;
        box-shadow: 0px 1px 18px rgba(0, 0, 0, 0.08);
        border-radius: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 92px;
        color: #FFFFFF;
    }

    @supports (-webkit-touch-callout: none) {
        .main-faq-rightSide .head {
            font-family: 'Manrope';
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 22px;
            color: #FFFFFF;
            margin-bottom: 4px;
        }

        .main-faq-rightSide .subHead {
            font-family: 'Manrope';
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            color: #B8B8B8;
            opacity: 0.8;
        }

        .main-review-rightSide .head {
            height: 22px;
            left: 32px;
            top: 1579px;

            font-family: 'Manrope';
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 22px;
            /* or 138% */


            color: #FFFFFF;
        }

        .main-radio-text-heading {
            font-weight: 600;
        }
        .mobikwik-text-container .header {
       
            font-family: 'Manrope';
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            color: #FFFFFF;
        }
    }
}

@media screen and (min-width: 760px) {
    .main-header-navbar {
        padding-top: 34px;

    }

    .main-aliste-logo {
        width: 45px;
        height: 42px;
    }

    .main-wab-logo {
        width: 100%;
        align-items: center;
        display: flex;
    }

    .main-wab-logo img {
        margin-right: 50px;
    }

    .main-wab-navbar {
        display: flex;
    }

    .main-wab-navbar div {
        display: inline-block;
        width: max-content;

        transition: all 350ms ease;
        font-family: 'Manrope';
        font-size: 15px;
        font-weight: 500;
        cursor: pointer;

    }

    .main-wab-navbar div a {
        text-decoration: none;
        color: #FFFFFF;
        padding: 0 30px;
    }

    .main-wab-navbar div a:hover {
        color: #2050ff;
    }

    .main-background {

        background-color: black;
        padding-bottom: 20px;
    }

    .main-rupess-header {
        height: 176px;
        left: 194px;
        top: 166px;

        font-family: 'Manrope';
        font-style: normal;
        font-weight: 800;
        font-size: 80px;
        line-height: 88px;
        /* or 110% */


        color: #FFFFFF;
    }

    .main-day {
        height: 176px;
        left: 194px;
        top: 166px;

        font-family: 'Manrope';
        font-style: normal;
        font-weight: 400;
        font-size: 40px;
        line-height: 88px;
        /* or 220% */


        color: #FFFFFF;
    }

    .main-topHeader {
        height: 630px;
        padding-left: 10%;

        background: rgba(32, 80, 255, 0.5);
        border-radius: 0px 0px 10px 10px;
    }

    .main-topHeader-inner {
        padding-top: 110px;
        display: flex;
        width: 100%;
    }

    .main-header-text {
        width: 50%;

    }

    .main-header-mainText {
        height: 176px;


        font-family: 'Manrope';
        font-style: normal;
        font-weight: 400;
        font-size: 80px;
        line-height: 88px;
        /* or 110% */


        color: #FFFFFF
    }

    .main-header-subText {
        height: 80px;
        margin-top: 20px;
        width: 70%;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 400;
        font-size: 30px;
        line-height: 40px;
        /* or 133% */


        color: #E1E1E1;
    }

    .main-header-img-container {
        background: url(../../../public/DesktopAssets/money.png);

        width: 33%;
        height: 306px;
        position: relative;
        top: -5px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    .main-header-img {
        width: 759px;
        height: 596px;
        display: none;

    }

    .main-2nd-container {
        width: 100%;
        position: relative;
        height: 120px;
    }
    
    .mobikwik-container {
        height: 72px;
        background: #121212;
        box-shadow: 0px 1px 18px rgba(0, 0, 0, 0.08);
        border-radius: 12px;
        width: 80%;
        margin-left: 10%;
        margin-right: 10%;
        margin-top: 24px;
        display: flex;
        /* flex-direction: column; */
        /* justify-content: center; */
        align-items: center;

    }

    .mobikwik-img-container img {
        margin-left: 20px;
        width: 47px;
        height: 39px;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        margin-right: 15px;
    }

    .mobikwik-text-container .header {
       
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 800;
        font-size: 18px;
        line-height: 22px;
        color: #FFFFFF;
    }

    .mobikwik-text-container .subHead {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 22px;
        color: #B8B8B8;
        opacity: 0.8;
    }
   

    .main-mainScreen {
        width: 32%;
    }

    .main-description-container {
        height: 173px;
        position: absolute;
        top: -58px;
        width: 80%;
        margin-left: 10%;
        margin-right: 10%;
        background: #121212;
        box-shadow: 0px 1px 18px rgb(0 0 0 / 8%);
        border-radius: 12px;
    }

    .main-img-text-container {
        display: flex;
        align-items: center;
        padding-left: 20px;
        padding-top: 19px;
    }

    .main-img-logo img {
        width: 55px;
        height: 55px;
    }

    .main-img-Cname {
        height: 22px;
        margin-left: 16px;
        top: 247px;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 800;
        font-size: 32px;
        line-height: 22px;
        letter-spacing: 0.01em;
        color: #FFFFFF;
    }

    #root {
        background-color: #121212 !important;
    }

    .main-description-heading {

        border-bottom: 0.2px solid #B8B8B8;
        padding-bottom: 15px;
        margin-left: 20px;
        margin-right: 15px;
        margin-top: 15px;
        height: 18px;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 18px;
        /* identical to box height, or 138% */


        color: #B8B8B8;
    }

    .main-description-subHeading {
        height: 15px;
        margin-left: 20px;
        margin-right: 15px;
        margin-top: 15px;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 18px;
        color: #B8B8B8;
        opacity: 0.8;
    }

    .main-selectPlan-text-m {
        display: none;
    }

    .main-img-logo {
        width: 55px;
        height: 55px;
    }
    .main-list {
        display: flex;
    }

    .main-list img {
        width: 14px;
        height: 9px;
        margin-right: 11px;
    }

    .main-list-text {
        margin-right: 10px;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        color: #B8B8B8;
    }

    .main-payButton-container {
        display: none;
    }

    .main-payButton-value-container {
        height: 46px;
        display: flex;
        margin-left: 16px;
        margin-top: 20px;
        flex-direction: column;
        justify-content: space-between;
    }

    .main-payButton-rate {
        height: 24px;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 800;
        font-size: 18px;
        line-height: 24px;
        color: #FFFFFF;
    }

    .main-payButton-security {
        height: 18px;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
        color: rgba(184, 184, 184, 0.7);
        opacity: 0.8;
    }

    .main-payButton-btn-container {
        width: 151px;
        height: 52px;
        margin-top: 20px;
        margin-right: 15px;
        background: #2050FF;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .main-payButton-btn-text {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        color: #FFFFFF;
    }

    .footer-logo {
        width: 140px;
        margin-bottom: 25px;
    }

    .main-footer-text {
        font-family: 'Manrope';
        font-style: normal;
        padding-bottom: 20px;
        color: #999;
        line-height: 24px;
    }

    .footer-title-light {
        font-family: 'Manrope';
        font-style: normal;
        margin-bottom: 16px;
        color: #999;
        font-size: 14px;
        line-height: 32px;
        font-weight: 500;
        letter-spacing: -0.01em;
    }

    .footer-main-container {
        display: flex;
        justify-content: space-between;
    }

    .footer-main-left-Side {
        width: 33%;
    }

    .main-footer-right-Side {
        width: 50%;
        display: flex;
        justify-content: space-between;
    }

    

    .main-logo-socials {
        margin-top: 32px;
        width: 43%;
        display: flex;
        justify-content: space-between;
    }

    .sm-icon {
        width: 25px;
        height: 25px;
    }

    .footer-column {
        display: flex;
        flex-direction: column;
    }

    .footer-text-link {
        margin-top: 0px;
        margin-bottom: 0px;
        font-size: 14px;
    }

    .footer-text-link {
        margin-top: -10px;
        margin-bottom: 0px;
        -webkit-transition: color 300ms ease;
        transition: color 300ms ease;
        color: #000;
        font-size: 14px;
        line-height: 48px;
        letter-spacing: -0.01em;
        text-decoration: none;
        font-family: 'Manrope';
        font-style: normal;
    }
    
    a {
        background-color: transparent;
    }

    .footer-text-link:hover {
        color: #1355ff;
    }

    a:active,
    a:hover {
        outline: 0;
    }

    .footer-title-light-copy {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        margin-top: 40px;
        margin-bottom: 0px;
        padding-bottom: 0px;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        color: #999;
        font-family: 'Manrope';
        font-style: normal;
        padding-bottom: 40px;
    }

    .main-mobile-Summary {
        width: 66%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .main-plan-notSeleted {
        width: 307.54px;
        height: 169px;
        background: #121212;
        box-shadow: 0px 1px 18px rgba(0, 0, 0, 0.08);
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .main-plan-notSeleted-test {

        height: 40px;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 40px;
        color: #FFFFFF;
    }

    .main-plan-notSeleted-text-subHead {
        height: 40px;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 400;
        font-size: 28px;
        line-height: 40px;
        color: #FFFFFF;
        text-align: center;
    }

    .main-plan-notSeleted-text-subHeading {
        height: 40px;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 40px;
        color: #FFFFFF;
        text-align: center;
    }

    /* 
    .bottom-mobile-main {
        display: none;
    } */
 
}