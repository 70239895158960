
@media screen and (max-width: 460px) {
  body {
    background-color: white;
  }
  .lead__main-container {
    padding: 15px;
    padding-bottom: 0;
    width: 100%;
    background: hsla(0, 0%, 100%, 1);

    background-image: url("../../../assets/LeadGeneration/lead_generation_background.webp");
    object-fit: contain;
    background-size: auto;
    
    background-repeat: no-repeat;
  }
  
  .lead__container {
    padding: 20px;
   
  }
  .transperent_leadInputBox{
    background-color: transparent;
  }

  .lead__aliste_logo {
    width: 90px;
    height: auto;
    margin-top: 8px;
  }

  .lead__content {
    padding: 10px;
    position: relative;
  
  }

  .lead__content-title-bold {
    font-size: 2rem;
    font-weight: bold;
    font-family: "Manrope";
    z-index: 2;
    position: relative;
  }

  .lead__content-title-light {
    font-size: 2rem;
    font-family: Manrope;
  }
.time-text{
  color: rgb(173, 178, 188)
}
  .lead__form-container {
    margin-top: 20px;
    padding: 20px 10px;
    .popBox{
      width: 100%;
      margin-left: 0;
      margin-top: 0;
      box-shadow: 0 4px 10px 0 hsla(0, 0%, 0%, 0.126);
      border: none !important;
      background-color: #fff;
      position: relative;
      top: -1;
      border-top-left-radius: 0 ;
      border-top-right-radius: 0 ;
    }
  }
  .lead__content-text {
    padding: 15px 0;
    font-size: 1rem;
    font-family: Manrope;
    position: relative;
  }

  .lead__form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  input {
    width: 100%;
    border-radius: 10px;
    font-family: Manrope;
    background-color: transparent;
  }
  
  .lead__callback-button {
    background-color: #2050ff;
    color: white;
    padding: 10px;
    border-radius: 20px;
    margin-top: 10px;
    box-shadow: 0 4px 10px 0 hsla(0, 0%, 0%, 0.458);
    font-family: Manrope;
    font-weight: bold;
  }
  @supports (-webkit-touch-callout: none) {
    .lead__callback-button{
      font-weight: 550;
    }
  }

  .lead__input-box {
    display: flex;
    gap: "25px";
    box-shadow: 0 4px 10px 0 hsla(0, 0%, 0%, 0.126);
    border-radius: 10px;
    padding: 5px 15px;
    background-color: #fff;
  }

  .lead__input-box img {
    width: 40px;
    height: auto;
    margin-right: 20px;
  }

  .lead__get-in-touch {
    position: absolute;
    top: -5rem;
    right: -3rem;
    width: 250px;
  }

.lead__success-success-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
  gap: 10px;
  position: relative;
  margin-top: 14px;
}

  .img__lead-success-icon {
    width: 100px;
    height: auto;
  }

  .lead__success-title {
    font-family: Manrope;
    font-size: 2rem;
    font-weight: bold;
}
.lead__success-text {
  font-family: Manrope;
  width: 230px;
  margin: 0 auto;
  margin-bottom:34px;

  text-align: center;

}

  .lead__success-box {
    border: 0.5px solid #ddd1d1;
    padding: 32px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    position: relative;
    margin-top: 20px;
    background-color: white;
    border-radius: 20px;
    margin: 0 5px;
  }

  .lead__success-box-title {
    font-family: Manrope;
    font-size: 2rem;
    font-weight: bold;
  }

  .lead__success-box-text {
    width: 300px;
    font-family: Manrope;
  }

  .lead__success-box-blue {
    color: #2050FF;
    font-weight: 500;
  }

  .lead__success-button {
    background-color: #2050ff;
    color: white;
    padding: 10px;
    border-radius: 20px;
    margin-top: 10px;
    box-shadow: 0 4px 10px 0 hsla(0, 0%, 0%, 0.458);
    font-family: Manrope;
    font-weight: bold;
    margin-top: 200px;
    z-index: 2;
  }
  @supports (-webkit-touch-callout: none) {
    .lead__success-button{
      font-weight: 550;
    }
    .lead__success-box-title{
      font-weight: 600;
    }
  }

  .lead__success-getInTouch {
    width: 200px;
    height: auto;
    position: absolute;
    bottom: 63px;
    right: 40px;
  }
  .lead_animation_tick{
    height: 100px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .react-calendar{
    position: relative;
    z-index: 33333333333333333;
  }
}

@media screen and (min-width: 760px) {
  body,#root {
    background: white !important;
    
  }
  .time-text{
    font-size: 12px;
  }
  .lead_animation_tick{
    height: 100px;
    
  }
  .leagBackGroundImage{
    background-image: url("../../../assets//lead/leadBackground.webp");
    
  }
  .lead__dk-main-container {
    background: transparent;
    width: 1200px;
    margin: auto;
    padding: 24px 100px;
    
  }

  .lead__dk-container {
    
    position: relative;
    .lead__dk-aliste-logo{
      margin-bottom: 100px;
      img{
        width: 120px;
      }
    }
    .popBox{
      width: 100%;
      margin-left: 0;
      margin-top: 0;
      bottom:0px;
      box-shadow: 0 4px 10px 0 hsla(0, 0%, 0%, 0.126);
      border: none !important;
      background-color: #fff;
      position: relative;
      top: -1;
      border-top-left-radius: 0 ;
      border-top-right-radius: 0 ;
    }
  }

  .lead__dk-content {
    margin-top: 50px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 150px;
  }

  .lead__dk-content-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 48%;
    gap: 15px;

    h1 {
      font-size: 50px;
      font-weight: 700;
      font-family: Manrope;
    }

    p {
      font-family: Manrope;
      margin-top: 20px;
      font-size: 20px;
    }
  }

  .lead__dk-content-right {
    display: flex;
    flex-direction: column;
    width: 48%;
    // background: #fff;
    border-radius: 20px;
    padding: 10px;
  }

  .lead__form-dk {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    font-family: Manrope;
    padding: 30px;
    border-radius: 38.33px;
    background-color: rgba(255, 255, 255, .45);
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, .25);
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, .25);
    backdrop-filter: blur(15px);
    // background: linear-gradient(
    //   120deg,
    //   rgba(255, 255, 255, .25),
    //   rgba(255, 255, 255, .75), 70%, 
    //   rgba(255, 255, 255, .25)
    // );

    h2 {
      font-size: 2rem;
      font-weight: bold;
      font-family: Manrope;
    }

    p {
      font-family: Manrope;
    }
  }

  .lead__form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  input {
    width: 100%;
    border-radius: 10px;
    font-family: Manrope;
    background-color: transparent;
  }

  .lead__callback-button {
    background-color: #2050ff;
    color: white;
    padding: 10px;
    border-radius: 20px;
    margin-top: 10px;
    box-shadow: 0 4px 10px 0 hsla(0, 0%, 0%, 0.458);
    font-family: Manrope;
    z-index: 0;
    font-weight: bold;
  }

  .lead__input-box {
    display: flex;
    gap: "25px";
    box-shadow: 0 4px 10px 0 hsla(0, 0%, 0%, 0.126);
    border-radius: 10px;
    padding: 5px 15px;
    background-color: #fff;
  }

  .lead__input-box img {
    width: 35px;
    height: auto;
    margin-right: 15px;
  }

  .lead__form-success {
    background: #f5f5f5;
    padding-top: 40px;
    border-radius: 20px;
    // background-color: rgba(255, 255, 255, .45);
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, .25);
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, .25);
    backdrop-filter: blur(15px);
  }

  .lead__form-success-thanks {
    display: flex;
    position: relative;
    gap: 40px;
    justify-content: center;


    img {
      width: auto;
      height: 95px;
    }

    .lead__form-success-thanks-text {
      display: flex;
      flex-direction: column;
      gap: 10px;

      h3 {
        font-size: 2rem;
        font-family: Manrope;
        font-weight: bold;
      }
      
      p {
        font-family: Manrope;
      }
    }
  }

  .lead__form-callback {
    display: flex;
    padding: 20px 40px;
    background: white;
    backdrop-filter: blur(15px);
    flex-direction: column;
    border-radius: 21px;
    gap: 20px;
    position: relative;
    margin-top: 20px;
    justify-content: space-between;
    

    h3 {
      font-size: 2rem;
      font-family: Manrope;
      font-weight: bold;
    }

    p {
      font-family: Manrope;
    }
    span {
      color: #2050ff;
      font-weight: "300";
    }
    img {
      position: absolute;
      width: 150px;
      height: auto;
      bottom: 54px;
      right: 80px;
    }

    button {
      margin-top: 150px;
    }
  }
}
.react-datepicker-wrapper{
  display: flex !important;
  align-items: center;
}
.react-date-picker {
  width: 77%;
}
.react-date-picker__wrapper {
  border: none !important;
  width: 90%;
}
.rs-input-group{
  border: none !important;
}