@media screen and (max-width: 460px) {

    .OurMarket-container {
        width: 100%;
        /* border-top: 8px solid white; */
        /* border-bottom: 8px solid white; */
        padding: 13px 13px;
        background-color: #f9f9f9;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        margin-top: 44px;
        margin-bottom: 44px;
    }

    .market-logo-container {
        overflow-x: scroll;
        display: flex;
        width: 100%;
        gap: 40px;
        margin-left: 40px;
        align-items: center;

        .img1 {
            width: 44px;
            height: 46px;
        }

        .img2 {
            width: 36.42px;
            height: 33.29px;
        }

        .img3 {
            width: 60.91px;
            height: 33.17px;
        }

        .img4 {
            width: 71.32px;
            height: 23.95px;
        }

        .img5 {
            width: 74px;
            height: 27px;
        }

        .img6 {
            width: 68.13px;
            height: 20.94px;
        }

        .img7 {
            width: 71px;
            height: 26px;
        }

        .img8 {
            width: 64.58px;
            height: 12.19px;
        }
    }


}

@media screen and (min-width: 760px) {
    .OurMarket-container {
        width: 100%;
        /* border-top: 8px solid white; */
        /* border-bottom: 8px solid white; */
        padding: 13px 13px;
        background-color: #f9f9f9;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        margin-top: 44px;
        margin-bottom: 44px;
    }

    .market-logo-container {
        overflow-x: scroll;
        display: flex;
        width: 100%;
        gap: 80px;
        margin-left: 40px;
        align-items: center;
        .img1 {
            width: 84px;
            /* height: 46px; */
        }

        .img2 {
            width: 66.42px;
            /* height: 33.29px; */
        }

        .img3 {
            width: 120.91px;
            /* height: 33.17px; */
        }

        .img4 {
            width: 141.32px;
            /* height: 23.95px; */
        }

        .img5 {
            width: 144px;
            /* height: 27px; */
        }

        .img6 {
            width: 128.13px;
            /* height: 20.94px; */
        }

        .img7 {
            width: 141px;
            /* height: 26px; */
        }

        .img8 {
            width: 124.58px;
            /* height: 12.19px; */
        }
    }
}