@media screen and (max-width: 461px) {
    .Summary-arrow {
        display: flex;
        padding-left: 4%;
        height: 55px;

        background: #FFFFFF;
    }

    .wabsummry-bottom-container {
        display: none;
    }

    .summary-head {

        margin-top: 20px;

        font-family: 'Manrope';
        font-style: normal;
        font-weight: 800;
        font-size: 15px;
        line-height: 20px;
        color: #0E112A;
    }

    @supports (-webkit-touch-callout: none) {

        /* CSS specific to iOS devices */
        .summary-head {
            font-weight: 600;
        }
    }

    .summary-plan-container {

        margin-top: 10%;
        padding-bottom: 1%;
        background: #FFFFFF;
        border-radius: 12px;
    }

    .summary-planName-rate-container {
        display: flex;
        margin-bottom: 2px;
        justify-content: space-between;
        margin-left: 5%;
        margin-right: 5%;
        padding-top: 4%;

    }

    .summary-planDetail {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        margin-left: 5%;
        width: 71%;
        color: rgba(14, 17, 42, 0.7);
    }

    .summary-planRate {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;

        color: rgba(14, 17, 42, 0.9);
    }

    .hr-summary {
        border: 0.5px dashed rgba(0, 0, 0, 0.1);
        margin-top: 19px !important;
    }

    .summary-planRate-offer {
        margin-left: 5%;
        margin-top: 3%;
        margin-bottom: 3%;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;

        color: rgba(14, 17, 42, 0.7);

    }

    .summary-planRate-offer span {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;

        color: #4C9D89;
    }


    .summary-apply-coupon {
        padding: 0 4%;
        margin-top: 6%;
        height: 64px;
        background: #FFFFFF;
        border-radius: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: rgba(14, 17, 42, 0.9);
    }

    .arrow-apply-coupon {
        float: right;
    }

    .summary-plan-toPay {
        margin-top: 6%;
        padding-top: 4%;
        background: #FFFFFF;
        border-radius: 12px;
    }

    .summary-details {

        display: flex;
        margin: 2% 4%;
        align-items: center;
        justify-content: space-between;
    }

    .summary-detail-title {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: rgba(14, 17, 42, 0.7);
    }

    @supports (-webkit-touch-callout: none) {

        /* CSS specific to iOS devices */
        .summary-detail-title {
            font-weight: 500;
        }
    }

    .summary-detail-rate {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        text-align: right;
        color: rgba(14, 17, 42, 0.9);
    }

    .summary-detail-rate1 {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 800;
        font-size: 14px;
        line-height: 20px;
        text-align: right;
        color: rgba(14, 17, 42, 0.9);
    }

    .summary-hr {
        margin-top: 4%;
        margin-bottom: 1px;
    }

    .div-hr {
        color: rgba(0, 0, 0, 0.2);
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0.8' stroke-linecap='square'/%3e%3c/svg%3e");
        height: 1px;
        border-radius: 10px;
    }

    .summary-partial-container1 {
        display: none;
    }

    .summary-partial-container {
        position: relative;
        width: 100%;
        height: 72px;
        background: linear-gradient(269.89deg, rgba(0, 150, 33, 0.13) -1.42%, rgba(33, 154, 81, 0.006) 99.15%), #FFFFFF;
        box-shadow: 0px 1px 18px rgba(0, 0, 0, 0.08);
        border-radius: 12px;
    }

    .summary-partial-banner {
        position: absolute;
        left: 26px;
        top: -7px;
        width: fit-content;
        height: 14px;
        line-height: 14px;
        vertical-align: middle;
        padding: 0 5px;
        text-align: center;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 700;
        font-size: 7px;
        color: #000000;
        background: #F2C851;
        border-radius: 2px;
    }

    .summary-partial-detail-container {
        display: flex;
        justify-content: space-between;
        padding: 0 4%;

    }

    .summary-partial-detail {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .summary-partial-detail-heading {
        width: fit-content;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        color: rgba(14, 17, 42, 0.9);
    }

    .summary-partial-detail-remaing {
        width: fit-content;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 700;
        font-size: 13px;
        color: #1F846C;
        opacity: 0.8;
    }

    .summary-partial-detail-300 {

        line-height: 72px;
        vertical-align: middle;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 800;
        font-size: 16px;
        color: rgba(14, 17, 42, 0.9);
    }

    .talk-to-us {
        overflow: hidden;
        background: #FFFFFF;
        border-radius: 12px;
        margin-top: 6%;
        margin-bottom: 115px;
        height: 159.51px;
        display: flex;
    }

    .sumarry-talk-to-left {
        margin-left: 7%;
        padding-top: 7.5%;
        width: 45%;
    }

    .summary-talk-title {
        color: #2552B1;
        font-size: 16px;
        font-weight: 600;
    }

    .summary-talk-subtitle {
        color: #161616;
        font-size: 13px;
        margin-top: 2%;
        font-weight: 500;
    }

    .summary-talk-btn {
        background: #2552B1;
        height: 43.68px;
        width: 131px;
        border-radius: 12px;
        color: #FFFFFF;
        font-size: 12px;
        font-weight: 600;
        text-align: center;
        vertical-align: middle;
        line-height: 43.68px;
        margin-top: 8%;
    }

    .sumarry-talk-to-right {

        height: 100%;
        width: 45%;
        margin-left: 4%;

    }

    .sumarry-talk-to-right img {
        position: relative;
        left: 25%;
        top: 8%;
        height: 92%;
    }

    .summry-bottom-container {
        position: fixed;
        bottom: 0;
        width: 100%;
        border-radius: 12px;
        height: 92px;

        background: #FFFFFF;
        box-shadow: 0px -4px 18px rgba(0, 0, 0, 0.12);
    }

    .summary-bottom-btn {
        width: 90%;
        margin-left: 5%;
        height: 52px;
        margin-top: 5%;
        text-align: center;
        vertical-align: middle;
        line-height: 52px;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        /* identical to box height, or 125% */

        text-align: center;

        color: #FFFFFF;

        background: #2552B2;
        border-radius: 12px;
    }

    .Paid-png-container {
        position: absolute;
        left: 34%;
        top: -14px;
    }

    .Paid-png {
        width: 100px;
        height: 76px;
    }

    .line-otp {
        position: relative;
        top: -9px;
        width: 20px;
        height: 1px;
        background: #D9D9D9;
    }

    .input-otp {
        width: 220px;
        height: 40px;
        border: none;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        padding-left: 1em;
        letter-spacing: 2.1em;
        color: #000000;
    }

    input:focus-visible {
        outline: none !important;
    }

    .line-cont {
        width: 220px;
        display: flex;
        justify-content: space-around;
    }

    .summary-planName {
        /* position: absolute;
        top: 6%;
        left: 4%; */
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: rgba(14, 17, 42, 0.9);
    }

    .summary-detailss {
        display: flex;
        margin: 4.5% 4%;
        justify-content: space-between;
        padding-bottom: 4.5%;
    }

    .summary-details-img {
        position: relative;
        display: flex;
        justify-content: space-between;
        margin: 0px 4%;
        padding: 5% 0px;
    }

    .summary-notice {
        font-size: 10px;
    }

    @supports (-webkit-touch-callout: none) {

        /* CSS specific to iOS devices */
        .summary-planName {
            font-weight: 600;
        }

        .summary-planRate {
            font-weight: 600;
        }

        .arrow-apply-coupon {
            font-weight: 600;
        }

        .summary-detail-rate1 {
            font-weight: 600;
        }
    }
}

@media screen and (min-width:800px) {
    .summary-plan-toPay {
        padding: 5px 0;
        height: max-content;
        background: #FFFFFF;
        border-radius: 12px;
    }

    .summary-notice {
        font-size: 10px;
    }

    .div-hr {
        color: rgba(0, 0, 0, 0.2);
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0.8' stroke-linecap='square'/%3e%3c/svg%3e");
        height: 1px;
        border-radius: 10px;
    }

    .summary-details {

        display: flex;
        margin: 7.17px 4%;
        justify-content: space-between;
    }

    .Summary-arrow {
        display: none;
    }

    .summary-plan-container {
        padding-bottom: 1%;
        background: #FFFFFF;
        border-radius: 12px;
    }

    .summary-planName {
        /* position: absolute;
        top: 6%;
        left: 4%; */
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: rgba(14, 17, 42, 0.9);
    }

    .summary-planName-rate-container {
        display: flex;
        margin-bottom: 2px;
        justify-content: space-between;
        margin-left: 5%;
        margin-right: 5%;
        padding-top: 4%;
    }

    .summary-planDetail {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        margin-left: 5%;
        width: 71%;
        color: rgba(14, 17, 42, 0.7);
    }

    .summary-planRate {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;

        color: rgba(14, 17, 42, 0.9);
    }

    .hr-summary {
        border: 1px dashed rgba(0, 0, 0, 0.1);
        margin-top: 7px !important;
    }

    .summary-planRate-offer {
        margin-left: 5%;
        margin-top: 3%;
        margin-bottom: 3%;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;

        color: rgba(14, 17, 42, 0.7);

    }

    .summary-planRate-offer span {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;

        color: #4C9D89;
    }

    .summary-partial-container {
        display: none;
    }

    .summary-partial-container1 {
        position: relative;
        width: 100%;
        height: 72px;
        margin-top: 6%;
        background: linear-gradient(269.89deg, rgba(0, 150, 33, 0.13) -1.42%, rgba(33, 154, 81, 0.006) 99.15%), #FFFFFF;
        box-shadow: 0px 1px 18px rgba(0, 0, 0, 0.08);
        border-radius: 12px;
    }

    .summary-partial-banner {
        position: absolute;
        left: 26px;
        top: -7px;
        width: 83px;
        height: 14px;
        line-height: 14px;
        vertical-align: middle;
        text-align: center;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 700;
        font-size: 7px;
        color: #000000;
        background: #F2C851;
        border-radius: 2px;
    }

    .summary-partial-detail-container {
        display: flex;
        justify-content: space-between;
        padding: 0 4%;

    }

    .summary-partial-detail {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .summary-partial-detail-heading {
        width: 160px;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 800;
        font-size: 16px;
        color: rgba(14, 17, 42, 0.9);
    }

    .summary-partial-detail-remaing {
        width: fit-content;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 700;
        font-size: 13px;
        color: #1F846C;
        opacity: 0.8;
    }

    .summary-partial-detail-300 {

        line-height: 72px;
        vertical-align: middle;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 800;
        font-size: 16px;
        color: rgba(14, 17, 42, 0.9);
    }

    .talk-to-us {
        display: none;
    }

    .summary-apply-coupon {
        padding: 0 4%;
        margin-top: 6%;
        height: 64px;
        background: #FFFFFF;
        border-radius: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: rgba(14, 17, 42, 0.9);
    }

    .arrow-apply-coupon {
        float: right;
    }

    .wab-view-payment-summary-container {
        display: flex;
        justify-content: space-between;
       
    }

    .wabview-paymentPlans {
        width: 100%;
    }

    .wab-view-1 {
        width: 30%;
    }

    .summry-bottom-container {
        display: none;
    }

    .summary-apply-coupon div {
        cursor: pointer;
    }

    .wabsummry-bottom-container {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        cursor: pointer;
        text-align: center;
        color: #FFFFFF;
        margin-top: 34px;
        width: 30%;
        border-radius: 12px;
        height: 52px;
        vertical-align: middle;
        line-height: 52px;
        background: #2552B2;
        filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.16));
    }

    .wab-summary-details {
        width: 68%;
    }

    .line-otp {
        position: relative;
        top: -9px;
        width: 20px;
        height: 1px;
        background: #D9D9D9;
    }

    .input-otp {
        width: 220px;
        height: 40px;
        border: none;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        padding-left: 1em;
        letter-spacing: 2.1em;
        color: #000000;
    }

    input:focus-visible {
        outline: none !important;
    }

    .line-cont {
        width: 220px;
        display: flex;
        justify-content: space-around;
    }

    .summary-detailss {
        display: flex;
        margin: 2.5% 4%;
        justify-content: space-between;
    }

    .summary-detail-rate {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        /* identical to box height, or 143% */

        text-align: right;

        color: rgba(14, 17, 42, 0.9);
    }

    .summary-detail-title {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        /* identical to box height, or 143% */


        color: rgba(14, 17, 42, 0.7);
    }
}