@media screen and (max-width: 460px) {
    .booking-Container {
        height: 72px;
        width: 100%;
        border-radius: 12px;
        background: linear-gradient(273deg, rgba(66, 255, 108, 0.77) -12.33%, rgba(174, 254, 197, 0.80) 90.68%), #FFF;
        box-shadow: 0px 1px 18px 0px rgba(0, 0, 0, 0.08);
        margin: 5% 0;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 5%;

        .offer-Container {
            position: absolute;
            height: 14px;
            border-radius: 2px;
            top: -7px;
            left: 7%;
            line-height: 14.5px;
            background: #F2C851;
            color: #000;
            font-family: Manrope;
            font-size: 7px;
            font-style: normal;
            text-align: center;
            font-weight: 700;

            padding: 0 2%;
            /* 142.857% */
        }

        @supports (-webkit-touch-callout: none) {
            .offer-Container {
                font-weight: 600;
            }
        }

        .payBookingText {
            color: rgba(0, 0, 0, 0.90);
            font-family: Manrope;
            font-size: 16px;
            font-style: normal;
            font-weight: 800;
            line-height: 22px;
            /* 137.5% */
        }

        @supports (-webkit-touch-callout: none) {
            .payBookingText {
                font-weight: 600;
            }
        }

        .remainingAmount {
            color: #0E7E19;
            font-family: Manrope;
            font-size: 13px;
            font-style: normal;
            font-weight: 700;
            line-height: 18px;
            /* 138.462% */
        }

        @supports (-webkit-touch-callout: none) {
            .remainingAmount {
                font-weight: 500;
            }
        }

        .fixedBookingAmount {
            color: #000;
            text-align: right;
            font-family: Manrope;
            font-size: 22.756px;
            font-style: normal;
            font-weight: 800;
            line-height: 25px;
            display: flex;
            align-items: flex-start;
            gap: 4px;
            /* 137.5% */
        }

        @supports (-webkit-touch-callout: none) {
            .fixedBookingAmount {
                font-weight: 600;
            }
        }
    }
}

@media screen and (min-width: 760px) {
    .booking-Container {
        height: 85px;
        width: 100%;
        border-radius: 12px;
        background: #FFF;
        box-shadow: 0px 1px 18px 0px rgba(0, 0, 0, 0.08);
        margin: 20px 0;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 5%;

        .offer-Container {
            position: absolute;
            height: 17px;
            border-radius: 2px;
            top: -7px;
            display: flex;
            left: 2%;
            line-height: 0;
            background: #F2C851;
            color: #000;
            font-family: Manrope;
            font-size: 12px;
            font-style: normal;
            text-align: center;
            font-weight: 700;
            padding: 0 2%;
            align-items: center;
        }

        @supports (-webkit-touch-callout: none) {
            .offer-Container {
                font-weight: 600;
            }
        }

        .payBookingText {
            color: rgba(0, 0, 0, 0.90);
            font-family: Manrope;
            font-size: 18.99px;
            font-style: normal;
            font-weight: 800;
            line-height: 26px;
            /* 137.5% */
        }

        @supports (-webkit-touch-callout: none) {
            .payBookingText {
                font-weight: 600;
            }
        }

        .remainingAmount {
            color: #0E7E19;
            font-family: Manrope;
            font-size: 15.43px;
            font-style: normal;
            font-weight: 700;
            line-height: 21.4px;
            /* 138.462% */
        }

        @supports (-webkit-touch-callout: none) {
            .remainingAmount {
                font-weight: 500;
            }
        }

        .fixedBookingAmount {
            color: #000;
            text-align: right;
            font-family: Manrope;
            font-size: 31.34px;
            font-style: normal;
            font-weight: 800;
            line-height: 43.5px;
            display: flex;
            align-items: flex-start;
            gap: 4px;
            /* 137.5% */
        }

        @supports (-webkit-touch-callout: none) {
            .fixedBookingAmount {
                font-weight: 600;
            }
        }
    }
}