@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  padding: 0;
  background-color: black;

  box-sizing: border-box;
  /* background-color: #dee4f2; */

}
@media screen and (min-width: 700px){

/* #root,body{
  background-color: #CBD4EA;
  height: 100vh;
} */

}
@media screen and (max-width: 400px){

  
  
  }

