@media screen and (max-width: 460px) {
.CustomPlan-container{

  width:99vw;
  background: #E5EAF5;
}
.CustomPlan2-container{
  width:99vw;
  background: #E5EAF5;
}
  .payment-confirmation-header{
 padding-left: 4%;
}
  .payment-confirmation-welcome{
    padding-top: 30px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 800;
    font-size: 28px;
    line-height: 40px;
    color: #0E112A;
  }
  .payment-confirmation-welcome-sub{
    font-family: 'Manrope';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 20px;
color: rgba(14, 17, 42, 0.7);
  }
 
 .payment-confirmation-welcome-sub1{
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: rgba(14, 17, 42, 0.7);
  }
  .payment-confirmation-details{
   
    margin-top: 6%;
    
    background: #FFFFFF;
    border-radius: 12px;
    padding-top: 5%;
    padding-bottom: 3%;
  }
  .payment-confirmation-specific{
    display: flex;
    margin-left: 4%;
    margin-right: 4%;
    margin-bottom: 2%;
    justify-content: space-between;
  }
  .payment-confirmation-specific-left{
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: rgba(14, 17, 42, 0.9);
  }
  .payment-confirmation-specific-right{
    font-family: 'Manrope';
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 16px;
color: rgba(14, 17, 42, 0.7);
  }
  .payment-confirmation-download-btn{
    margin-top: 6%;
    height: 52px;
    background: #2552B2;
    border-radius: 12px;
    filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.16));
  }
  .payment-confirmation-download-btn-text{
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 52px;
    text-align: center;
    color: #FFFFFF;
  }
  .payment-confirmation-botton-cantainer{
    margin-top: 6%;
    padding-top: 5%;
    background: #FFFFFF;
    border-radius: 15px 15px 0px 0px;
  }
  .payment-confirmation-botton-cantainer-img{
    width:77px;   
    height:19.78px;
    margin-left: 4%;
  }
  .payment-confirmation-botton-cantainer-img img{
    width:77px;   
    height:19.78px;
  }
  .payment-confirmation-botton-cantainer-img1{
    margin-top: 16px;
    width: 211px;
    height: 12px;
    margin-left: 4%;
  }
  .payment-confirmation-botton-cantainer-img1 img{
    width: 211px;
    height: 12px;
  }
  .payment-confirmation-botton-cantainer-text{
    margin-top: 20px;
    margin-left: 4%;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: rgba(14, 17, 42, 0.7);
    padding-bottom: 20px;
  }
  .confirmation-plan-toPay {
    margin-top: 6%;
    padding-top: 4%;
    background: #FFFFFF;
    border-radius: 12px;
}
.confirmation-plan-container {
        
  margin-top: 10%;
  padding-bottom: 1%;
  background: #FFFFFF;
  border-radius: 12px;
}
.confirmation-planName-rate-container{
  display: flex;
  margin-bottom: 2px;
  justify-content: space-between;
  margin-left: 5%;
  margin-right: 5%;
  padding-top: 4%; 
  
}
.confirmation-planName {
  /* position: absolute;
  top: 6%;
  left: 4%; */
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: rgba(14, 17, 42, 0.9);
}
.confirmation-planRate {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;

  color: rgba(14, 17, 42, 0.9);
}
.confirmation-planDetail {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-left: 5%;
  width: 71%;
  color: rgba(14, 17, 42, 0.7);
  }
  .confirmation-planRate-offer {
    margin-left: 5%;
    margin-top: 3%;
    margin-bottom: 3%;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;

    color: rgba(14, 17, 42, 0.7);

}

.confirmation-planRate-offer span {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;

    color: #4C9D89;
}
.confirmation-details {
        
  display: flex;
  margin: 2% 4%;
  align-items: center;
  justify-content: space-between;
}
.confirmation-detail-title {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: rgba(14, 17, 42, 0.7);
}
.confirmation-detail-rate {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  color: rgba(14, 17, 42, 0.9);
}
.confirmation-hr {
  margin-top: 4%;
  margin-bottom: 1px;
}
.confirmation-details-img{
  position: relative;
display: flex;
justify-content: space-between;
margin: 0px 4%;
padding: 5% 0px;
}
.confirmation-detail-rate1 {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  color: rgba(14, 17, 42, 0.9);
}
.confirmation-Paid-png {
  width: 100px;
height: 76px;
}
.payment-confirmation-Booking-container{
  width: 100%;
  position: relative;
height: 53px;
margin-top: 6%;
background: #FFFFFF;
border-radius: 12px;
display: flex;
justify-content: space-between;
padding: 0 6%;
align-items: center;
}
.payment-confirmation-Booking-title{
  width: fit-content;

font-family: 'Manrope';
font-style: normal;
font-weight: 800;
font-size: 16px;

color: rgba(14, 17, 42, 0.9);
}
.confirmation-Paid-png-container{
  position: absolute;
 
  top: -10px;
  left:40%
}
.confirmation2-Paid-png-container{
  position: absolute;
  left: 35%;
  top: -13px;
}
.payment-confirmation-download-btn-wab{
  margin-top: 6%;
  height: 52px;
  background: #2552B2;
  border-radius: 12px;
  filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.16));
}
  @supports (-webkit-touch-callout: none) {
    .payment-confirmation-welcome{
      font-weight: 600;
      margin-top: 35px;
    }
    .payment-confirmation-welcome-sub1{
      font-size: 15px;
    }
    .confirmation-detail-title{
      font-weight: 500;
    }
    
  }
}
@media screen and (min-width: 760px) {
 
  .payment-confirmation-Booking-container{
    width: 100%;
    position: relative;
  height: 53px;
  margin-top: 6%;
  background: #FFFFFF;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  padding: 0 6%;
  align-items: center;
  }
  .payment-confirmation-Booking-title{
    width: fit-content;
    white-space: nowrap;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  
  color: rgba(14, 17, 42, 0.9);
  }
  
  .CustomPlan-container{
    width: 350px;
    background: #E5EAF5;
    padding: 1%;
    margin-left: 35%;
    padding-bottom: 0;
  }
  .CustomPlan2-container{
  
      width: 350px;
      background: #E5EAF5;
      padding: 1%;
      padding-bottom: 0;
      margin-left: 35%;
      padding-bottom: 0;
    }
  .payment-confirmation-header{
    padding-left: 4%;
   }
     .payment-confirmation-welcome{
       margin-top: 30px;
       
       height: 40px;
       font-family: 'Manrope';
       font-style: normal;
       font-weight: 800;
       font-size: 28px;
       line-height: 40px;
       color: #0E112A;
     }
     .payment-confirmation-welcome-sub{
       font-family: 'Manrope';
   font-style: normal;
   font-weight: 500;
   font-size: 14px;
   line-height: 20px;
   color: rgba(14, 17, 42, 0.7);
     }
     
    .payment-confirmation-welcome-sub1{
       font-family: 'Manrope';
       font-style: normal;
       font-weight: 400;
       font-size: 13px;
       line-height: 18px;
       color: rgba(14, 17, 42, 0.7);
     }
     .payment-confirmation-details{
       margin-top: 6%;
       background: #FFFFFF;
       border-radius: 12px;
       padding-top: 5%;
       padding-bottom: 3%;
     }
     .payment-confirmation-specific{
       display: flex;
       margin-left: 4%;
       margin-right: 4%;
       margin-bottom: 2%;
       justify-content: space-between;
     }
     .payment-confirmation-specific-left{
       font-family: 'Manrope';
       font-style: normal;
       font-weight: 700;
       font-size: 14px;
       line-height: 20px;
       color: rgba(14, 17, 42, 0.9);
     }
     .payment-confirmation-specific-right{
       font-family: 'Manrope';
   font-style: normal;
   font-weight: 500;
   font-size: 12px;
   line-height: 16px;
   color: rgba(14, 17, 42, 0.7);
     }
     .payment-confirmation-download-btn-wab{
       margin-top: 6%;
       height: 52px;
       background: #2552B2;
       border-radius: 12px;
       filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.16));
       cursor: pointer;
     }
     .payment-confirmation-download-btn-text{
       font-family: 'Manrope';
       font-style: normal;
       font-weight: 700;
       font-size: 16px;
       line-height: 52px;
       text-align: center;
       color: #FFFFFF;
     }
     .payment-confirmation-botton-cantainer{
       margin-top: 6%;
       padding-top: 5%;
       background: #FFFFFF;
       border-radius: 15px 15px 0px 0px;
     }
     .payment-confirmation-botton-cantainer-img{
       width:77px;   
       height:19.78px;
       margin-left: 4%;
     }
     .payment-confirmation-botton-cantainer-img img{
       width:77px;   
       height:19.78px;
     }
     .payment-confirmation-botton-cantainer-img1{
       margin-top: 16px;
       width: 211px;
       height: 12px;
       margin-left: 4%;
     }
     .payment-confirmation-botton-cantainer-img1 img{
       width: 211px;
       height: 12px;
     }
     .payment-confirmation-botton-cantainer-text{
       margin-top: 20px;
       margin-left: 4%;
       font-family: 'Manrope';
       font-style: normal;
       font-weight: 400;
       font-size: 13px;
       line-height: 20px;
       color: rgba(14, 17, 42, 0.7);
       padding-bottom: 20px;
     }
     .confirmation-plan-toPay {
       margin-top: 6%;
       padding-top: 4%;
       background: #FFFFFF;
       border-radius: 12px;
   }
   .confirmation-plan-container {
           
     margin-top: 10%;
     padding-bottom: 1%;
     background: #FFFFFF;
     border-radius: 12px;
   }
   .confirmation-planName-rate-container{
     display: flex;
     margin-bottom: 2px;
     justify-content: space-between;
     margin-left: 5%;
     margin-right: 5%;
     padding-top: 4%; 
     
   }
   .confirmation-planName {
     /* position: absolute;
     top: 6%;
     left: 4%; */
     font-family: 'Manrope';
     font-style: normal;
     font-weight: 700;
     font-size: 14px;
     line-height: 20px;
     color: rgba(14, 17, 42, 0.9);
   }
   .confirmation-planRate {
     font-family: 'Manrope';
     font-style: normal;
     font-weight: 700;
     font-size: 14px;
     line-height: 20px;
   
     color: rgba(14, 17, 42, 0.9);
   }
   .confirmation-planDetail {
     font-family: 'Manrope';
     font-style: normal;
     font-weight: 400;
     font-size: 12px;
     line-height: 16px;
     margin-left: 5%;
     width: 71%;
     color: rgba(14, 17, 42, 0.7);
     }
     .confirmation-planRate-offer {
       margin-left: 5%;
       margin-top: 3%;
       margin-bottom: 3%;
       font-family: 'Manrope';
       font-style: normal;
       font-weight: 700;
       font-size: 12px;
       line-height: 16px;
   
       color: rgba(14, 17, 42, 0.7);
   
   }
   
   .confirmation-planRate-offer span {
       font-family: 'Manrope';
       font-style: normal;
       font-weight: 600;
       font-size: 12px;
       line-height: 16px;
   
       color: #4C9D89;
   }
   .confirmation-details {
           
     display: flex;
     margin: 2% 4%;
     align-items: center;
     justify-content: space-between;
   }
   .confirmation-detail-title {
     font-family: 'Manrope';
     font-style: normal;
     font-weight: 600;
     font-size: 14px;
     line-height: 20px;
     color: rgba(14, 17, 42, 0.7);
   }
   .confirmation-detail-rate {
     font-family: 'Manrope';
     font-style: normal;
     font-weight: 600;
     font-size: 14px;
     line-height: 20px;
     text-align: right;
     color: rgba(14, 17, 42, 0.9);
   }
   .confirmation-hr {
     margin-top: 4%;
     margin-bottom: 1px;
   }
   .confirmation-details-img{
     position: relative;
   display: flex;
   justify-content: space-between;
   margin: 0px 4%;
   padding: 5% 0px;
   }
   .confirmation-detail-rate1 {
     font-family: 'Manrope';
     font-style: normal;
     font-weight: 800;
     font-size: 14px;
     line-height: 20px;
     text-align: right;
     color: rgba(14, 17, 42, 0.9);
   }
   .confirmation-Paid-png {
     width: 100px;
   height: 76px;
   }
   .confirmation-Paid-png-container{
     position: absolute;
   
     top: -10px;
     left: 40%;
   }
   .confirmation2-Paid-png-container{
    position: absolute;
    left: 35%;
    top: -13px;
  }
   .payment-confirmation-download-btn{
       display: none;
   }
   .payment-confirmation-download-btn-wab{
    margin-top: 6%;
    height: 52px;
    background: #2552B2;
    border-radius: 12px;
    filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.16));
  }
}