@media screen and (max-width: 460px) {
    .input-container-img-inputBox {
        height: 50px;
        border-radius: 6px;
        width: 90%;
        margin: auto;
        display: flex;
        align-items: center;
        margin-top: 1rem;
        position: relative;

        .img-container {
            margin-left: 3.4%;
            margin-right: 3.4%;
            img {
                width: 36px;
            }
        }
        input{
            background: transparent;
        }
        .applyIcon{
            position: absolute;
            width: 36px;
            right: 5%;
        }
    }
    .planDetailMobile{
        width: 90%; 
        margin: auto; 
        margin-top: 5%;
    }
    
}
@media screen and (min-width: 760px) {
    .input-container-img-inputBox {
        height: 51.5px;
    border-radius: 6px;
    width: 90%;
    margin: auto;
    display: flex;
    align-items: center;
    margin-top: 3.2%;
    position: relative;

        .img-container {
            margin-left: 3.4%;
            margin-right: 3.4%;
            img {
                width: 36px;
            }
        }
        input{
            background: transparent;
        }
        .applyIcon{
            position: absolute;
            width: 36px;
            right: 5%;
        }
    }
    .topContainer{
        display: flex;
        width: 500px;
        flex-direction: column;
    }
    .planDetailMobile{
        width: 700px;
    }
}