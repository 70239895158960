@media screen and (max-width: 460px) {
    .feature-container {
        width: 90%;
        margin: 0 5%;
        
        margin-top: 14px;
        background: #121212;
        box-shadow: 0px 1px 18px rgba(0, 0, 0, 0.08);
        border-radius: 12px;
        padding-left: 14.65px;
        padding-right: 14.65px;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-bottom: 90px;
    }

    .feature-text {
        height: 12px;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 700;
        font-size: 11px;
        line-height: 12px;
        color: #FFFFFF;
    }
    .single-faeture-text-conainer{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 24.62px;
        border-bottom: 0.647964px solid rgba(255, 255, 255, 0.13);
    }
    .single-faeture-text-conainer:last-child{
        border: none;
    }
    @supports (-webkit-touch-callout: none) {
        .feature-text {
            height: 12px;
            font-family: 'Manrope';
            font-style: normal;
            font-weight: 600;
            font-size: 11px;
            line-height: 12px;
            color: #FFFFFF;
        }
    }
}
@media screen and (min-width: 760px) {
    .feature-container {
      display: none;
    }
}    