@media screen and (max-width: 460px) {
    .gpay-container {
        width: 90%;
        margin-left: auto;
        display: flex;
        justify-content: space-between;
        margin-right: auto;
        margin-top: 10px;
    }
    .gpay-container_rating{
        display: flex;
        align-items: center;
        gap: 3px;
    }
    .gpay-star-container{
        margin-left: 6px;
        margin-bottom: 2px;
        display: flex;
        gap: 2px;
    }
    .gpay-container_rating img {
        height: 14px;
        width: 14px;
    }

    .gpay-rating {
        height: 20px;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #E1E1E1;
        margin-left: 6px;
    }
     .gpay-right{
        display: flex;
        align-items: center;
        justify-content: space-between;
    
     }
    .gpay-star-container img {
        width: 13px;
        height: 12px;
    }

    .gpay-star-container span {
        height: 20px;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #E1E1E1;
        margin-left: 10px;
    }
    .gpayimg{
      height: 13.83px;
      margin-top: 2px;
    }
    .review{
        margin-left: 5px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    color: #8F8F8F;
    margin-top: 1px;
    }
    .customer{
        height: 16px;
        margin-left: 6px;
font-family: 'Manrope';
font-style: normal;
font-weight: 400;
font-size: 11px;
line-height: 16px;
/* identical to box height, or 143% */


color: #C9C9C9;
    }
    .gpay-logo-text{
        margin-bottom: 3px;
        display: flex;
    }
    @supports (-webkit-touch-callout: none) {
        .review{
          font-weight: 500;
        
        }
        .gpay-right{
           width: 60%;
        }
        .gpay-logo-text{
            margin-bottom: 4px;
        }
        .customer{
            height: 16px;
            margin-left: 2px;
            font-family: 'Manrope';
            font-style: normal;
            font-weight: 400;
            font-size: 11px;
             line-height: 16px;
    
            color: #C9C9C9;
        }
        .gpay-star-container{
           margin-bottom: 5px;
        }
    }   
}
@media screen and (min-width: 760px) {
    .gpay-container {
        display: flex;
        justify-content: space-between;
        margin-top:19.5px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
    .gpay-container_rating{
        display: flex;
        align-items: center;
     
    }
    .gpay-container_rating img {
        height: 21.91px;
        width: 21.91px;
    }
    .gpay-star-container{
        margin-left: 14px;
    }
    .gpay-rating {
        
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 400;
        font-size: 21.9149px;
        
        color: #E1E1E1;
        margin-left: 6px;
    }
    .gpay-star-container{
        display: flex;
        align-items: center;
        margin-bottom: 2px;
    }
    .gpay-star-container img {
        
        height: 20px;
    }

    .gpay-star-container span {
        height: 32px;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 400;
        font-size: 21.9149px;
        line-height: 31px;
        color: #E1E1E1;
        margin-left: 10px;
    }
    .gpayimg{
        height: 21.23px;
      }
      .gpay-right{
        display: flex;
        align-items: center;
        width: 63%;
        justify-content: space-between;
      }
      .review{
        font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 20.2296px;
    color: #8F8F8F;
    margin-left: 6px;
    margin-bottom: 5px;
      }
      .customer{
        height: 25px;
        text-align: center;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 400;
        font-size: 17.3397px;
        line-height: 25px;
        /* identical to box height, or 143% */
        
        width:60%;
        color: #E1E1E1;
      }
}