@media screen and (max-width: 460px) {

    .banner-container {
        margin: 0 5%;
        margin-top: 51px;
        margin-bottom: 44px;
        width: 90%;
        display: flex;
        align-items: center;
    }

    .single-banner-container {
        width: 25%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .banner-text {
        height: 22px;
        margin-top: 12.19px;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 400;
        font-size: 9px;
        line-height: 12px;
        text-align: center;
        color: #FFFFFF;
    }

    .banner-divide-line {
        width: 0px;
        height: 48px;
        border: 0.25px solid #FFFFFF;
    }
    .img-banner1{
        width: 20px;
        height: 33.69px;
    }
    .img-banner2{
        width: 30px;
        height: 33px;
    }
    .img-banner3{
        width: 40.02px;
        height: 32.81px;
    }
    .img-banner4{
        width: 29px;
        height: 29px;
    }
}
@media screen and (min-width: 760px) {
    .banner-container {
        margin-top: 51px;
        margin-bottom: 44px;
        display: flex;
        align-items: center;
    }

    .single-banner-container {
        width: 25%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .banner-text {
        height: 22px;
        margin-top: 19.08px;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 400;
        font-size: 14.0882px;
        line-height: 19px;
        text-align: center;
        color: #FFFFFF;
    }

    .banner-divide-line {
        width: 0px;
        height: 75.14px;
        border: 0.391338px solid #FFFFFF;
    }
    .img-banner1{
        /* width: 47.78px; */
        height: 51.36px;
    }
    .img-banner2{
        /* width: 46.96px; */
        height:51.66px;
    }
    .img-banner3{
        /* width: 62.65px; */
        height: 51.36px;
    }
    .img-banner4{
        /* width: 54.79px; */
        height: 46.66px;
        margin-bottom: 6.5px;
    }
}