@media screen and (max-width: 460px) {
    .templateUi > div {
        display: flex;
        flex-direction: row;
        gap: 2;
        
    }
    .templateUi{
        display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0 20px;
    gap: 20px;
    }
   .inputCaptcha{
    width: 100%;
   }
    .templateUiContainer{
        width: 90%;
        margin: auto;
        display: flex;
       
        /* justify-content: space-between; */
        margin-top: 14px; 
        background-color: transparent;
        gap: 14px;
    }

    .captchBorder{
        border: 0.5px solid #00000054;
        border-radius: 6px;
        height: 43px;
    }
    .captchLine{
        font-style: italic;
        text-decoration: line-through;
    }
    .color{
        color: black;
        font-size: 23px;
    }
    .inputButton{
        display: flex;
        flex-direction: column;
    }
  
    .captcha-button{
       margin-left: 3px;
       width: 25%;
       height: 43px;
       border-radius: 7px;
       text-align: center;
       color: black;
       display: flex;
       justify-content: center;
       align-items: center;
    }
    .newinputBox{
        width: 100% !important;
    }
}

@media screen and (min-width: 760px){
    .templateUi > div {
        display: flex;
        flex-direction: row;
        gap: 2;
        
    }
    .templateUi{
        display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0 20px;
    gap: 20px;
    }
   .inputCaptcha{
    width: 100%;
   }
    .templateUiContainer{
        width: 90%;
        margin: auto;
        display: flex;
       
        /* justify-content: space-between; */
        margin-top: 14px; 
        background-color: transparent;
        gap: 14px;
    }

    .captchBorder{
        border: 0.5px solid #00000054;
        border-radius: 6px;
        height: 43px;
    }
    .captchLine{
        font-style: italic;
        text-decoration: line-through;
    }
    .color{
        color: black;
        font-size: 23px;
    }
    .inputButton{
        display: flex;
        flex-direction: column;
    }
  
    .captcha-button{
       margin-left: 3px;
       width: 25%;
       height: 43px;
       border-radius: 7px;
       text-align: center;
       color: black;
       display: flex;
       justify-content: center;
       align-items: center;
    }
    .newinputBox{
        width: 100% !important;
    }
}