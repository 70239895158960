@media screen and (max-width: 460px) {
    .selectedPlan {
        width: 100%;
        padding: 5% 0%;
        padding-top: 8%;
        display: flex;
        justify-content: space-between;

        .planContainer {
            width: 47.5%;
            height: 153.311px;
            border-radius: 11.652px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            // box-shadow: 0px 1.793px 6.274px 0px rgba(0, 0, 0, 0.25);
            .header {
                color: #272940;
                font-family: Manrope;
                font-size: 12.549px;
                font-style: normal;
                font-weight: 400;
                line-height: 16.025px;
                text-align: center;
                /* 127.706% */
            }

            .subHeader {
                color: #272940;
                font-family: Manrope;
                font-size: 20.86px;
                font-style: normal;
                font-weight: 400;
                line-height: 28.683px;
                text-align: center;
                margin-top: 1px !important;
                .heeadddd{
                   font-weight: 800;
                }
                @supports (-webkit-touch-callout: none) {
                    .heeadddd {
                        font-weight: 600;
                    }
                }
            }

            img {
                width: 70px;
                height: auto;
            }
        }
        .main-main{
            position: relative;
        }
        .sub-rotate{
            // transform: rotate(6deg);  
            position: absolute;
            top: 0;
            background-color: #FFFFFF;
            width: 100%;
            
        }
        .main-rotate{
            transform: rotate(-4deg);
    background-color: #DFE0E1;
    position: absolute;
    height: 100px;
    top: -8px;
    left: -6px;
    width: 94%;
        }
    }

}

@media screen and (min-width: 760px){
    .selectedPlanContainer{
        
        display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 20px;
        .selectedPlanDetail{
            width: 287.63px;
            height: 253.54px;
            border-radius: 21.24px;
            background-color: #FFFFFF;
            display: flex;
            flex-direction: column;
            align-items: center;
            // box-shadow: 0px 10px 4px #00000025;            

            .subHeader{
                text-align: center;
                margin-top: 20px;
                font-size: 22.87px;
                line-height: 29.2px;
                color: #272940;
                font-family: 'Manrope';
            }
            .header{
                text-align: center;
               
                font-size: 38.02px;
                line-height: 52.3px;
                color: #272940;
                font-family: 'Manrope'
            }
            img{
                width: 112px;
            }
        }
        .selectedPlanDetailOption{
            display: flex;
            width: 287.63px;
            flex-wrap: wrap;
            justify-content: space-between;
            gap: 11px;
            margin-top: 11px;
            text-align: center;
            align-items: center;
            justify-content: center;
            .planContainerName{
                width: 80.86px;
                cursor: pointer;
                height: 47.94px;
                border-radius: 9.33px;
                background-color: #FFFFFF;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                .headFirst{
                   font-size: 13.85px;
                   line-height: 19.1px;
                   font-family: Manrope;
                   font-weight: 600;
                }
                .headSecond{
                    font-size: 13.85px;
                    line-height: 19.1px;
                    font-family: Manrope;
                    font-weight: 400;
                    margin-left: 3px;
                }
            }
        }
    }
}