@media screen and (max-width: 460px) {
    .header {
        color: #0E112A;
        font-family: Manrope;
        font-size: 24px;
        font-style: normal;
        font-weight: 800;
        line-height: 30px;
        width: 100%;
        /* 125% */
    }
    @supports (-webkit-touch-callout: none) {
        .header{
            font-weight: 600;
        }
    }
}
@media screen and (min-width: 760px){
    .header {
        color: #0E112A;
        font-family: Manrope;
        font-size: 44px;
        font-style: normal;
        font-weight: 800;
        line-height: 30px;
        width: 100%;
        /* 125% */
    }
    @supports (-webkit-touch-callout: none) {
        .header{
            font-weight: 600;
        }
    }
}