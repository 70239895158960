@media screen and (max-width: 460px) {
  .innerBannerContainers{
      width: 100%;
      background-color: #fff;
      padding-top: 1px;
      margin-top: 5%;
  }
.bannerContainers{
    width: 90%;
    margin: auto;
    margin-top: 5%;
   
    display: flex;
    flex-direction: column;
    gap: 5%;
  .bannerImg{
    height: auto;
    width: 100%;
    margin-bottom: 5%;
  }
  // .bannerImg:last-child{
  //   margin-bottom: 0;
  // }
}
}

@media screen and (min-width: 760px){
  .innerBannerContainers{
    width: 100%;
    background-color: #fff;
    padding-top: 20px;
    margin-top: 30px;
}
  .bannerContainers{
    width: 100%;
    margin: auto;
    // margin-top: 5%;
    padding-bottom: 40px;
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 5%;
    .innerContainerB{
      width: 1200px;
      margin: auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  .bannerImg{
    height: auto;
    width: 100%;
    margin-bottom: 5%;
  }
  .bannerImg:last-child{
    margin-bottom: 0;
  }
}
}