@media screen and (max-width: 460px) {
    .imgContainerr {
        width: 43vw;
        position: relative;
        border-radius: 23px;
        padding: 22px;

        .imgContainer {
            display: flex;
            justify-content: center;
            width: 100%;
            height: 72.298px;

            img {
                max-height: 100%;
                max-width: 100%;
            }
        }

        .testImg {
            display: flex;
            justify-content: center;
            margin-top: 9px;
            flex-direction: column;
            align-items: center;

            gap: 2px;

            .textName {
                color: #000;
                font-family: Manrope;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                letter-spacing: 0.018px;
            }
            
        }
        .freeText{
            font-family: Manrope;
          font-size: 10px;
          font-style: normal;
          font-weight: 600;
          line-height: 10px;
        }
    }

    .offer {
        color: #000;
        font-family: Manrope;
        font-size: 7px;
        border-radius: 2px;
        background: #F2C851;
        font-style: normal;
        line-height: 10px;
        position: absolute;
        padding: 2px 11px;
        /* 142.857% */
        top: -7px;
        left: 6%;
        font-weight: 700;
    }


    @supports (-webkit-touch-callout: none) {
        .offer {
            font-weight: 600;
        }
    }
}

@media screen and (min-width: 760px) {
    .imgContainerr {
        width: 136.79px;
        height: 134.42px;
        position: relative;
        border-radius: 23px;
        cursor: pointer;

        .imgContainer {
            display: flex;
            justify-content: center;
            width: 100%;
            height: 72.298px;

            img {
                max-height: 100%;
                max-width: 100%;
            }
        }

        .testImg {
            display: flex;
            justify-content: center;
            margin-top: 9px;
            flex-direction: column;
            align-items: center;

            gap: 2px;

            .textName {
                color: #000;
                font-family: Manrope;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                letter-spacing: 0.018px;
            }
            
        }
        .freeText{
            font-family: Manrope;
          font-size: 10px;
          font-style: normal;
          font-weight: 600;
          line-height: 10px;
        }
    }
    .imgContainerr:last-child{
        margin-bottom: 40px;
    }

    .offer {
        color: #000;
        font-family: Manrope;
        font-size: 7px;
        border-radius: 2px;
        background: #F2C851;
        font-style: normal;
        line-height: 10px;
        position: absolute;
        padding: 2px 11px;
        /* 142.857% */
        top: -7px;
        left: 6%;
        font-weight: 700;
    }


    @supports (-webkit-touch-callout: none) {
        .offer {
            font-weight: 600;
        }
    }
}