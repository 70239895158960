@media screen and (max-width: 460px) {
    .reviews-container {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        height: 200px;
        margin-bottom: 5%;
        width: 100%;
        gap: 5%;
        background-color: #FFF;

        .singleReview {
            min-width: 151px;
            height: 189px;
            overflow: hidden;
            border-radius: 9.562px;
            background: #FFF;
            box-shadow: 0px 0.797px 14.344px 0px rgba(0, 0, 0, 0.08);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .starContainer {
                display: flex;
                margin-top: 2px;
            }

            .reviewerNameContainer {
                display: flex;
                align-items: center;
                gap: 5px;
                margin-top: 12px;
            }

            .reviewerName {
                color: #000;
                font-family: Manrope;
                font-size: 13px;
                font-style: normal;
                font-weight: 800;
                line-height: 17.531px;
                /* 134.854% */
            }
            @supports (-webkit-touch-callout: none) {
                .reviewerName {
                    font-weight: 600;
                }
            }

            .reviewDate {
                color: #272727;
                font-family: Manrope;
                font-size: 8.765px;
                font-style: normal;
                font-weight: 500;
                line-height: 14.344px;
                /* 163.636% */
            }

            .reviewText {
                color: #333;
                width: 130px;
                margin-top: 19.84px;
                text-align: center;
                height: 84px;
                overflow: hidden;
                font-family: Manrope;
                font-size: 10px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                position: relative;
                z-index: 1;
            }

            .quto {
                position: absolute;
                color: #EEE;
                z-index: -1;
                font-family: Manrope;
                font-size: 77.533px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
            .letfQuto{
                top:-34px;
                left:2px;
            }
            .rightQuto{
                bottom:-14px;
                right:2px;
                letter-spacing: -11px;
            }
            @supports (-webkit-touch-callout: none) {
                .quto {
                    font-weight: 600;
                }
            }
        }
    }
}

@media screen and (min-width: 760px) {
    .reviews-container {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        // height: 270px;
        height: -webkit-fill-available;
        width: 100%;
        gap: 20px;
        background-color: #FFF;
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 10px;


        .singleReview {
            min-width: 203.73px;
            // height: 255px;
            margin-top: 2px;
            overflow: hidden;
            border-radius: 19.98px;
            background: #FFF;
            box-shadow: 0px 0.797px 14.344px 0px rgba(0, 0, 0, 0.08);
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 22px;

            .starContainer {
                display: flex;
                margin-top: 2px;
            }

            .reviewerNameContainer {
                display: flex;
                align-items: center;
                gap: 5px;
                margin-top: 12px;
            }

            .reviewerName {
                color: #000;
                font-family: Manrope;
                font-size: 18.29px;
                font-style: normal;
                font-weight: 800;
                line-height: 22px;
                /* 134.854% */
            }
            @supports (-webkit-touch-callout: none) {
                .reviewerName {
                    font-weight: 600;
                    white-space: nowrap;
                }
            }

            .reviewDate {
                color: #272727;
                font-family: Manrope;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 18px;
                /* 163.636% */
            }

            .reviewText {
                color: #333;
                width: 130px;
                margin-top: 10.84px;
                text-align: center;
                height: -webkit-fill-available;
                overflow: hidden;
                font-family: Manrope;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                position: relative;
                z-index: 1;
            }

            .quto {
                position: absolute;
                color: #EEE;
                z-index: -1;
                font-family: Manrope;
                font-size: 77.533px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
            .letfQuto{
                top: -25px;
                left: -7px;
            }
            .rightQuto{
                bottom: -9px;
                right: 4px;
                letter-spacing: -11px;
            }
            @supports (-webkit-touch-callout: none) {
                .quto {
                    font-weight: 600;
                }
            }
        }
    }
}