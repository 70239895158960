@media screen and (max-width: 460px) {

   .OurClients-container {
      margin-top: 30px;
   }

   .OurClients-haeding {  
      height: 40px;
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 800;
      font-size: 14px;
      line-height: 40px;
      color: #FFFFFF;
      margin-left: 5%;
      margin-bottom: 20px;
   }
   
   .marquee-client{
      margin: 0 5%;
      width: 90% !important;
   }

   .client-logo-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
   }
   .client-logo-container img{
      margin-right: 60px;
   }
   .overlay{
      --gradient-color:black !important
   }
   .ourClientImg1{
      width: 46.84px;
      height: 20px;
   }
   .ourClientImg2{
      /* width: 38px; */
      height: 34px;
   }
   .ourClientImg3{
      /* width: 79px; */
      height: 29px;
   }
   .ourClientImg4{
      /* width: 79px; */
      height: 29px;
   }
   .ourClientImg5{
      /* width: 79px; */
      height: 24px;
   }
}

@media screen and (min-width: 760px) {
   .OurClients-container {
      margin-top: 46px;
      width: 1200px;
      margin-left: auto;
      margin-right: auto;
   }

   .OurClients-haeding {
      height: 77px;
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 800;
      font-size: 26.8722px;
      line-height: 77px;
      color: #FFFFFF;
      margin-left: 4%;
   }
   .marquee-client{
      margin-left:auto ;
      margin-left: auto;
      
   }
   .client-logo-container {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      width: 100%;
      margin-bottom: 64px;
   }
   .overlay{
      --gradient-color:black !important
   }
   .client-logo-container img{
      margin-right: 130px;
   }
   .ourClientImg1{
      /* width: 185.88px; */
      height: 46.71px;
   }
   .ourClientImg2{
      /* width: 72.94px; */
      height:65.26px;
   }
   .ourClientImg3{
      /* width: 151.64px; */
      height: 46.07px;
   }
   .ourClientImg4{
      /* width: 79px; */
      height: 55px;
   }
   .ourClientImg5{
      /* width: 79px; */
      height: 38px;
   }
}