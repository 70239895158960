@media screen and (max-width: 460px) {
    .frequency-container {
        width: 100%;
        display: flex;
        gap: 5%;
        flex-wrap: nowrap;
        overflow-x: scroll;
        margin: 5% 0%;
        padding-top: 10px;



        .rate {
            font-family: Manrope;
            font-size: 12.299px;
            font-style: normal;
            font-weight: 700;
            line-height: 17.57px;
            width: max-content;
            margin-right: 12px;
            white-space: nowrap;
        }

        @supports (-webkit-touch-callout: none) {
            .rate {
                font-weight: 600;
            }
        }
    }

    .planDetailContainer {
        border-radius: 18px;
        background: #FFF;
        padding: 5%;

        .singleRow {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 10px 0;

            .leftContainer {
                display: flex;
                gap: 23px;
                align-items: center;
            }

            img {
                height: 52px;
                width: 52px;
            }

            .head {
                color: #000;
                font-family: Manrope;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 14px;
                letter-spacing: 0.147px;
            }

            @supports (-webkit-touch-callout: none) {
                .head {
                    font-weight: 600;
                }
            }

            .subHead {
                color: rgba(14, 17, 42, 0.70);
                font-family: Manrope;
                font-size: 10px;
                font-style: normal;
                font-weight: 400;
                line-height: 14px;
                margin-top: 3px;
                /* 140% */
            }


        }

        .topRow {
            margin-top: 0;
        }

        .cost {
            color: #363636;
            font-family: Manrope;
            font-size: 21.135px;
            font-style: normal;
            font-weight: 700;
            line-height: 25px;

        }

        @supports (-webkit-touch-callout: none) {
            .cost {
                font-weight: 600;
            }
        }

        .taxCoast {
            color: #6D6D6D;
            font-family: Manrope;
            font-size: 14.305px;
            font-style: normal;
            font-weight: 700;
            line-height: 21px;
            display: flex;
            align-items: flex-start !important;
            gap: 4px;
            /* 147.926% */
        }

        @supports (-webkit-touch-callout: none) {
            .taxCoast {
                font-weight: 600;
            }
        }

        .line {
            margin: 5px 0;
            height: 1px;
            background:
                repeating-linear-gradient(90deg, #858585 0 5px, #0000 0 7px)
                /*5px red then 2px transparent -> repeat this!*/
        }

        .subTotalText {
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            align-items: center;
        }

        .subTotal {
            color: #000;
            font-family: Manrope;
            font-size: 16.766px;
            font-style: normal;
            font-weight: 700;
            line-height: 17.132px;
            /* 1062.478% */
            letter-spacing: 0.168px;
        }

        @supports (-webkit-touch-callout: none) {
            .subTotal {
                font-weight: 600;
            }
        }

        .taxes {
            color: #6D6D6D;
            font-family: Manrope;
            font-size: 12.509px;
            font-style: normal;
            font-weight: 500;
            line-height: 15.865px;
            /* 1246.067% */
            letter-spacing: 0.125px;
        }

        .subTotal-container {
            display: flex;
            justify-content: space-between;
            padding: 8px 0;
            flex-direction: column;
            gap: 8px;
        }

        .total-container {
            display: flex;
            justify-content: space-between;
            padding-top: 4%;
            color: #000;
            font-family: Manrope;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            letter-spacing: 0.2px;
        }

        @supports (-webkit-touch-callout: none) {
            .total-container {
                font-weight: 600;
            }
        }

        .total-container-coast {
            display: flex;
            align-self: flex-start !important;
            gap: 4px;
            line-height: 24px;
        }
    }

    .freqq {

        height: 38.854px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;

        gap: 6px;
        .freeDaysPlan{
            color: #000;
            font-family: Manrope;
            font-size:7px;
            font-style: normal;
            font-weight: 600;
            height: 14px;
            background-color: rgb(255 204 56);
            line-height: 7px;
            position: absolute;
            top: -7px;
            display: flex;
            align-items: center;
            padding: 0px 7px;
            border-radius: 2px;
            justify-content: center;
            text-align: center;
            left: 10px;
            white-space: nowrap;
        }
    }

    .fre {
        font-family: Manrope;
        font-size: 12.299px;
        font-style: normal;
        font-weight: 700;
        line-height: 19.327px;
        width: max-content;
        // margin-left: 12px;
        white-space: nowrap;
        /* 157.143% */
    }

    @supports (-webkit-touch-callout: none) {
        .fre {
            font-weight: 600;
        }
    }

    .sticky {
        position: fixed;
        left: 0;
        top: 51px;
        width: 100% !important;
        padding: 2% 5% 3% 5%;
        z-index: 34443334443;
        background: rgb(243, 245, 250);
        border-bottom: 0.5px solid #B8B8B8;
        transition: ease-in-out;
    }

    .taxCoastRupee {
        color: #6D6D6D;
        width: 6.555px;
        height: 7.151px;
        flex-shrink: 0;
        font-family: Manrope;
        font-size: 10.249px;
        font-style: normal;
        font-weight: 500;
        line-height: 18.634px;
        /* 181.818% */
    }
}

@media screen and (min-width: 760px) {
    .frequency-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
        overflow-x: scroll;
        padding-top: 10px;
        margin-bottom: 20px;
        gap: 20px;
        .freqq {
    
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 10px;
            min-width: 11rem;
            .freeDaysPlan{
                color: #000;
                font-family: Manrope;
                font-size:12px;
                font-style: normal;
                font-weight: 600;
                height: 18px;
                background-color: rgb(255 204 56);
                line-height: 9px;
                position: absolute;
                top: -7px;
                display: flex;
                align-items: center;
                padding: 0px 7px;
                border-radius: 2px;
                justify-content: center;
                text-align: center;
                left: 10px;
            }
        }


        .rate {
            cursor: pointer;
            font-family: Manrope;
            // font-size: 12.299px;
            font-style: normal;
            font-weight: 700;
            white-space: pre-wrap;
            white-space: nowrap;
        }

        @supports (-webkit-touch-callout: none) {
            .rate {
                font-weight: 600;
            }
        }
    }
    .planContain{
        width: 100%;
   

    .planDetailContainer {
        border-radius: 18px;
        background: #FFF;
        padding: 5%;

        .singleRow {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 4.8% 0;

            .leftContainer {
                display: flex;
                gap: 23px;
                align-items: center;
            }

            img {
                height: 52px;
                width: 52px;
            }

            .head {
                color: #000;
                font-family: Manrope;
                font-size: 21.26px;
                font-style: normal;
                font-weight: 700;
                line-height: 23px;
                letter-spacing: 0.147px;
            }

            @supports (-webkit-touch-callout: none) {
                .head {
                    font-weight: 600;
                }
            }

            .subHead {
                color: rgba(14, 17, 42, 0.70);
                font-family: Manrope;
                font-size: 14.49px;
                font-style: normal;
                font-weight: 400;
                line-height: 15.5px;
                margin-top: 3px;
                /* 140% */
            }


        }

        .topRow {
            margin-top: 0;
        }

        .cost {
            color: #363636;
            font-family: Manrope;
            font-size: 25px;
            font-style: normal;
            font-weight: 700;
            line-height: 27px;

        }

        @supports (-webkit-touch-callout: none) {
            .cost {
                font-weight: 600;
            }
        }

        .taxCoast {
            color: #6D6D6D;
            font-family: Manrope;
            font-size: 14.305px;
            font-style: normal;
            font-weight: 700;
            line-height: 21px;
            display: flex;
            align-items: flex-start !important;
            gap: 4px;
            /* 147.926% */
        }

        @supports (-webkit-touch-callout: none) {
            .taxCoast {
                font-weight: 600;
            }
        }

        .line {
            margin: 4px 0;
            height: 2px;
            background:
                repeating-linear-gradient(90deg, #858585 0 5px, #0000 0 7px)
                /*5px red then 2px transparent -> repeat this!*/
        }

        .subTotalText {
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            align-items: center;
        }

        .subTotal {
            color: #000;
            font-family: Manrope;
            font-size: 21.96px;
            font-style: normal;
            font-weight: 700;
            line-height: 23.132px;
            /* 1062.478% */
            letter-spacing: 0.168px;
        }

        @supports (-webkit-touch-callout: none) {
            .subTotal {
                font-weight: 600;
            }
        }

        .taxes {
            color: #6D6D6D;
            font-family: Manrope;
            font-size: 16.509px;
            font-style: normal;
            font-weight: 500;
            line-height: 17.865px;
            /* 1246.067% */
            letter-spacing: 0.125px;
        }

        .subTotal-container {
            display: flex;
            justify-content: space-between;
            padding: 8px 0;
            flex-direction: column;
            gap: 11px;
        }

        .total-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-top: 4%;
            color: #000;
            font-family: Manrope;
            font-size: 29px;
            font-style: normal;
            font-weight: 700;
            letter-spacing: 0.2px;
            line-height: 0;

        }

        @supports (-webkit-touch-callout: none) {
            .total-container {
                font-weight: 600;
            }
        }

        .total-container-coast {
            display: flex;
            align-self: flex-start !important;
            gap: 4px;
            line-height: 24px;
        }
    }


    .fre {
        font-family: Manrope;
        // font-size: 78%;
        cursor: pointer;
        font-style: normal;
        font-weight: 700;
        white-space: nowrap;
    }

    @supports (-webkit-touch-callout: none) {
        .fre {
            font-weight: 600;
        }
    }

    .taxCoastRupee {
        color: #6D6D6D;
        width: 6.555px;
        height: 7.151px;
        flex-shrink: 0;
        font-family: Manrope;
        font-size: 10.249px;
        font-style: normal;
        font-weight: 500;
        line-height: 18.634px;
        /* 181.818% */
    }
}
}