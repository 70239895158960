@media screen and (max-width: 460px) {
    .tickBlue{
        height: 10px;
        width: 10px;
    }
}

@media screen and (min-width: 760px) {
    .tickBlue{
        height: 13.43px;
        width: 13.35px;
    }
}