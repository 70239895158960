@media screen and (max-width: 460px) {
  .productHighLightContainer {
    margin-top: 5%;

    .topImage {
      // height: 160px;
      border-radius: 12px;
      width: 100%;
      overflow: hidden;
      margin-bottom: 5%;
    }

    .middleImageContaier {
      display: flex;
      gap: 5%;

      width: 100%;

      .middleLargeImage {
        // height: 356px;
        overflow: hidden;
        border-radius: 12px;
        width: 100%;
      }

      .middleImageSmall {
        display: flex;
        flex-direction: column;
        width: 100%;
        // height: 356px;
        justify-content: space-between;

      }

    }

    .bottomImage {
      // height: 160px;
      width: 100%;
      margin-top: 5%;
      border-radius: 12px;
      overflow: hidden;
    }

    .smallImageee-container {
      // height: calc(178px - 2.5%);
      border-radius: 12px;
      overflow: hidden;
    }

    .smallImageee {
      // height: calc(356px - 5%)/2;
      width: 100%;
      object-fit: cover;
    }
  }
}

@media screen and (min-width: 760px) {
  .smallImageee {
    height: 100%;
    object-fit: cover;
    border-radius: 15.25px;
  }
}