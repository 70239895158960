@media screen and (max-width: 460px) {
    .crative-Container{
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 30px;
    }
    .Creative-img {
        width: 100%;
        
    } 
}
@media screen and (min-width: 760px) {
    .crative-Container{
        display: flex;
        flex-direction: column;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }
    .Creative-img {
        width: 100%;
        
    }
}    